import * as React from 'react'
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu'
import { useNavigate } from 'react-router'
import path from '../../../model/path'

function MenuTools () {
  const linkRef = React.useRef(null)
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  function onOpen () {
    setOpen(true)
  }
  function onClose () {
    setOpen(false)
  }
  const menuItems = [
    {
      key: 'GMCWhiteList',
      text: 'GMCWhiteList',
      onClick: () => navigate(path.gmcToolWhiteList)
    },
    {
      key: 'GMCMultiStoreImport',
      text: 'GMCMultiStoreImport',
      onClick: () => navigate(path.gmcToolMultiStoreImport)
    },
    {
      key: 'Unbind Shopify Ads Account',
      text: 'Unbind Shopify Ads Account',
      onClick: () => navigate(path.shopifyToolUnbindAdsAccount)
    },
    {
      key: 'Unbind BigCommerce Ads Account',
      text: 'Unbind BigCommerce Ads Account',
      onClick: () => navigate(path.bigcommerceToolUnbindAdsAccount)
    },
    {
      key: 'Unbind PrestaShop Ads Account',
      text: 'Unbind PrestaShop Ads Account',
      onClick: () => navigate(path.prestashopToolUnbindAdsAccount)
    }
  ]
  return (
    <div className='header-item clickable' >
      <div onClick={onOpen} ref={linkRef}>Tools</div>
      <ContextualMenu
        items={menuItems}
        hidden={!open}
        target={linkRef}
        onItemClick={onOpen}
        onDismiss={onClose}
      />
    </div>
  )
}

export default MenuTools

/* eslint-disable camelcase */
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
import StatusName from '../model/statusName'
function Status_Count (props) {
  const columns = [
    {
      key: 'Status',
      fieldName: 'Status',
      name: 'Status',
      isResizable: true,
      minWidth: 160,
      maxWidth: 160
    },
    {
      key: 'Count',
      fieldName: 'Count',
      name: 'Count',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    }
  ]

  const data = props.data.map(item => {
    const newItem = { ...item }
    newItem.Status = StatusName[item.Status]
    return newItem
  })

  return (
    <DetailsList
      items={data}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
    />
  )
}
Status_Count.propTypes = {
  data: PropTypes.array.isRequired
}
export default Status_Count

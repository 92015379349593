/* eslint-disable camelcase */
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'

function NewGroupId_MerchantIds_OfferCount (props) {
  const columns = [
    {
      key: 'NewGroupId',
      fieldName: 'NewGroupId',
      name: 'NewGroupId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'OfferCount',
      fieldName: 'OfferCount',
      name: 'OfferCount',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'MerchantIds',
      fieldName: 'MerchantIds',
      name: 'MerchantIds',
      isResizable: true,
      minWidth: 120
    }
  ]
  const data = props.data.map(item => {
    const _newItem = { ...item }
    _newItem.MerchantIds = item.MerchantIds.join(',')
    return _newItem
  })
  return (
    <DetailsList
      items={data}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
    />
  )
}
NewGroupId_MerchantIds_OfferCount.propTypes = {
  data: PropTypes.array.isRequired
}
export default NewGroupId_MerchantIds_OfferCount

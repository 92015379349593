import React from 'react'
import { DetailsList, IconButton, SelectionMode, SpinButton, Stack } from '@fluentui/react'
import PropType from 'prop-types'

function QueryResultList (props) {
  const [itemsPerPage, setItemsPerPage] = React.useState(15)
  const [currentItem, setCurrentItem] = React.useState(0)
  const displayedItemsStart = Math.max(0, currentItem)
  const displayedItemsEnd = Math.min(props.data.length, currentItem + itemsPerPage)
  const displayedItems =
    props.data
      .slice(
        displayedItemsStart,
        displayedItemsEnd
      )
  return (
    <div>
      <div className='flex-row'>
        <h3>Query Results</h3>
        <div style={{ flexGrow: 1 }}/>
        <Stack>
          <SpinButton
            label="Items Per Page"
            onChange={(el, val) => {
              setItemsPerPage(Number(val))
            }}
            value={itemsPerPage}
            min={1}
            max={10000}
            step={1}
            incrementButtonAriaLabel="Increase value by 1"
            decrementButtonAriaLabel="Decrease value by 1"
            styles={{
              spinButtonWrapper: { width: 75 }
            }}
          />
        </Stack>
      </div>
      <DetailsList
        items={displayedItems}
        selectionMode={SelectionMode.none}
      />
      <div className='flex-row'>
        <IconButton iconProps={{ iconName: 'Previous' }} onClick={() => setCurrentItem(0)}/>
        <IconButton iconProps={{ iconName: 'ChevronLeft' }} onClick={() => setCurrentItem(Math.max(0, currentItem - itemsPerPage))}/>
        <IconButton iconProps={{ iconName: 'ChevronRight' }} onClick={() => setCurrentItem((currentItem + itemsPerPage >= props.data.length) ? currentItem : (currentItem + itemsPerPage))} />
        <IconButton iconProps={{ iconName: 'Next' }} onClick={() => setCurrentItem(Math.max(0, props.data.length - itemsPerPage))}/>
        <div style={{ flexGrow: 1 }}/>
        <div>{displayedItemsStart + 1}-{displayedItemsEnd} of {props.data.length} items</div>
      </div>
    </div>
  )
}
QueryResultList.propTypes = {
  data: PropType.array.isRequired
}
export default QueryResultList

import axios from 'axios'
import config from '../config'
import { addErrorHandler, addTokenHandler } from './general'

const instance = axios.create({
  baseURL: config.picadminMT
})

addErrorHandler(instance)
addTokenHandler(instance)

const executeQuery = async (sql) => {
  const result = await instance.post('/Query/ExecuteQuery',
    { sql },
    {}
  )
  return result.data
}

const getPublicQueries = async () => {
  const result = await instance.get('/Query/GetPublicQueries',
    {}
  )
  return result.data
}

async function getPersonalQueries (account) {
  const result = await instance.get('/Query/GetPersonalQueries',
    {
      params: {
        account
      }
    }
  )
  return result.data
}

async function upsertQuery (query) {
  const result = await instance.post('/Query/UpsertQuery',
    query,
    {}
  )
  return result.data
}

async function deleteQuery (queryId) {
  const result = await instance.delete('/Query/DeleteQuery',
    {
      params: {
        queryId
      }
    }
  )
  return result.data
}

async function upsertFavouriteQuery (account, queryId, like) {
  const result = await instance.post('/Query/UpsertFavouriteQuery',
    {},
    {
      params: {
        account,
        queryId,
        like
      }
    }
  )
  return result.data
}

async function getFavouriteQueryIds (account) {
  const result = await instance.get('/Query/GetFavouriteQueryIds',
    {
      params: {
        account
      }
    }
  )
  return result.data
}

export default {
  getPublicQueries,
  getPersonalQueries,
  upsertQuery,
  executeQuery,
  deleteQuery,
  upsertFavouriteQuery,
  getFavouriteQueryIds
}

import '../../asset/css/lineChart.css'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const Granularity = {
  DAILY: 'daily',
  MONTHLY: 'monthly'
}

function LineChart (props) {
  const ref = useRef(null)
  const [width, setWidth] = useState(0)
  const [popupIndex, setPopupIndex] = useState(-1)
  const baseline = 216
  const chartPaddingX = 48
  const chartPaddingY = 32
  const xAxisLabelStep = 3
  const chartHeight = 272
  const tooltipWidth = 320

  const dataMax = props.data.map((values) => {
    const maxValue = Math.max(...values)
    if (maxValue <= 0) return 4
    const base = 10 ** Math.floor(Math.log10(maxValue))
    const highDigit = Math.ceil(maxValue / base)
    const max = 4 * Math.ceil(highDigit / 4) * base
    return max
  })
  const dataMaxAll = Math.max(...dataMax)
  const hasData = props.data[0].length > 0
  const yAxisStep = (baseline - chartPaddingY) / 4

  function formatDate (date) {
    if (props.granularity === Granularity.DAILY) {
      return `${date.getMonth() + 1}/${date.getDate()}`
    }
    return `${date.getFullYear()}-${date.getMonth() + 1}`
  }
  function getDateByIndex (index) {
    const date = new Date(props.startDate)
    if (props.granularity === Granularity.DAILY) {
      date.setDate(date.getDate() + index)
      return date
    }
    date.setMonth(date.getMonth() + index)
    return date
  }
  // function getShape (value, index, dataIndex) {
  //   const x = getPositionX(index);
  //   const y = getPositionY(value, dataIndex);
  //   const r = 7;
  //   return `M${x} ${y - r} L${x + r} ${y} L${x} ${y + r} L${x - r} ${y} Z`;
  // }
  function getPositionY (value) {
    return baseline -
        (value / dataMaxAll) * (baseline - chartPaddingY)
  }
  function getPopupPositionX (index) {
    let x = getPositionX(index)
    if (x > width - tooltipWidth) x -= tooltipWidth // align left if reach to right corner
    return x
  }
  function getPositionX (index) {
    const dataSize = props.data[0].length - 1
    if (dataSize === 0) {
      return (width / 2)
    }
    return (index / dataSize) * (width - chartPaddingX * 2) + chartPaddingX
  }
  function onResize () {
    setWidth(ref.current.clientWidth)
  }
  function getAxisLabel (n) {
    const step = dataMaxAll / 4
    return dataMaxAll - step * (4 - n)
  }
  function getDataLinePath (dataIndex) {
    const str = props.data[dataIndex].reduce((pre, value, index) => {
      const x = getPositionX(index)
      const y = getPositionY(value)
      if (index === 0) {
        return `M${x} ${y}`
      }
      return `${pre} L${x} ${y}`
    }, '')

    return str
  }
  function onMounted () {
    // HACK: sometimes clientWidth can't get correct value. add 24px for padding.
    setWidth(ref.current.clientWidth - 24)
    window.addEventListener('resize', onResize)
  }
  function destroyed () {
    window.removeEventListener('resize', onResize)
  }
  useEffect(() => {
    onMounted()
    return destroyed
  }, [])
  return (
    <>
      <svg ref={ref} height={chartHeight} width="100%">
        {
          (function () {
            const x = [0]
            props.axis.forEach((item, axisIndex) => {
              x.push(item.length * 8 + 24 + x[x.length - 1])
            })
            return props.axis.map((item, axisIndex) => {
              return (
                <g key={axisIndex}>
                  <circle
                    className={`dot data${axisIndex + 1}`}
                    cx={8 + x[axisIndex] } cy={chartHeight - 10} r={8}
                  />
                  <text
                    x={16 + x[axisIndex] }
                    y={chartHeight - 4}
                    textAnchor='start'>
                    {item}
                  </text>
                </g>)
            })
          })()
        }
        <line
          className="axis-baseline"
          x1={chartPaddingX} y1={baseline}
          x2={chartPaddingX} y2={baseline - 4 * yAxisStep}
        />
        {
          // y Axis labels
          [0, 1, 2, 3, 4].map(n => (
            <g key={`axis-${n}`}>
              <line
                className="axis-dotline"
                x1={chartPaddingX} y1={baseline - n * yAxisStep}
                x2={width - chartPaddingX} y2={baseline - n * yAxisStep}
              />
              <line
                className="axis-baseline"
                x1={chartPaddingX - 4} y1={baseline - n * yAxisStep}
                x2={chartPaddingX} y2={baseline - n * yAxisStep}
              />
              { hasData &&
                <text x={chartPaddingX - 8} y={baseline - n * yAxisStep + 4} textAnchor='end' className='axis-label'>
                  { getAxisLabel(n) }
                </text>
              }
            </g>
          ))
        }
        <line
          className="axis-baseline"
          x1={chartPaddingX} y1={baseline}
          x2={width - chartPaddingX} y2={baseline}
        />
        {
          hasData &&
          <g>
            {
              props.axis.map((item, index) => <path key={index} d={getDataLinePath(index)} className={`line data${index + 1}`} />)
            }
            {
              props.axis.map((item, axisIndex) => {
                return props.data[axisIndex].map((_, index) => (
                  <g key={`datapoint-${index}`} className="data-group">
                    <circle
                      className={`dot data${axisIndex + 1}`}
                      cx={getPositionX(index)} cy={getPositionY(props.data[axisIndex][index])} r={6}
                      onMouseEnter={() => setPopupIndex(index)}
                    />
                  </g>)
                )
              })
            }
          </g>
        }
        {
          // x Axis labels
          (function () {
            if (!hasData) {
              return [
                <text key={0} x={chartPaddingX} y={chartHeight - 32} textAnchor='middle'>{ formatDate(props.startDate) }</text>,
                <text key={1} x={width - chartPaddingX} y={chartHeight - 32} textAnchor="middle">{ formatDate(props.endDate) }</text>
              ]
            };
            const labels = []
            const dataIter = new Date(props.startDate)
            for (let i = 0; i < props.data[0].length; i += xAxisLabelStep) {
              labels.push(
                <g key={i}>
                  <text x={getPositionX(i)} y={chartHeight - 28} textAnchor='middle' className='axis-label'>
                    { formatDate(dataIter) }
                  </text>
                  <line
                    className="axis-baseline"
                    x1={getPositionX(i)} y1={baseline}
                    x2={getPositionX(i)} y2={baseline + 4}
                  />
                </g>
              )
              dataIter.setDate(dataIter.getDate() + xAxisLabelStep)
            }
            return labels
          })()
        }
        {
          popupIndex >= 0 &&
          <g className='data-group' onMouseLeave={() => setPopupIndex(-1)}>
            {props.axis.map((item, axisIndex) => {
              return (
                <circle
                  key={axisIndex}
                  className={`dot data${axisIndex + 1}`}
                  cx={getPositionX(popupIndex)} cy={getPositionY(props.data[axisIndex][popupIndex])} r={6}
                />
              )
            })}
            <g className="popup-text" style={{ opacity: 0.8 }} >
              {/* <!-- TODO: placeholder --> */}
              <line
                className="value-indicator"
                x1={getPositionX(popupIndex)} y1={chartPaddingY}
                x2={getPositionX(popupIndex)} y2={baseline}
              />
              <rect x={getPopupPositionX(popupIndex)} y={chartHeight / 2 - 16 } width={tooltipWidth} height={(props.axis.length + 2) * 16} rx={4}/>
              <text x={getPopupPositionX(popupIndex) + 16} y={chartHeight / 2 + 4} style={{ fill: 'white' }}>
                Time: { formatDate(getDateByIndex(popupIndex)) }
              </text>
              {(function () {
                const _axis = props.axis.map((item, axisIndex) => {
                  return {
                    axis: item,
                    value: props.data[axisIndex][popupIndex]
                  }
                })
                _axis.sort((a, b) => a.value - b.value)
                _axis.reverse()
                return _axis.map((item, dataIndex) => {
                  return (
                    <text x={getPopupPositionX(popupIndex) + 16} y={chartHeight / 2 + 4 + 16 * (dataIndex + 1)} style={{ fill: 'white' }} key={dataIndex}>
                      { item.axis }: { item.value }
                    </text>
                  )
                })
              })()
              }
            </g>
          </g>
        }
      </svg>
    </>

  )
}
LineChart.propTypes = {
  data: PropTypes.array.isRequired,
  axis: PropTypes.array.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  granularity: PropTypes.string.isRequired
}
LineChart.defaultProps = {
  data: [[]],
  startDate: null,
  endDate: null
}
export default LineChart

import React from 'react'
import { PrimaryButton, Stack } from '@fluentui/react'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { loginRequest } from '../../../config/authConfig'
import { InteractionStatus } from '@azure/msal-browser'

function AccountStatus () {
  const MSSignInButton = () => {
    const { instance, inProgress } = useMsal()
    const handleLogin = (loginType) => {
      if (loginType === 'popup') {
        instance.loginPopup(loginRequest).catch(e => {
          console.log(e)
        })
      } else if (loginType === 'redirect') {
        instance.loginRedirect(loginRequest).catch(e => {
          console.log(e)
        })
      }
    }
    return (
      <PrimaryButton onClick={() => handleLogin('redirect')} disabled={inProgress === InteractionStatus.Login}>
        Sign in
      </PrimaryButton>
    )
  }

  const MSSignOutButton = () => {
    const { instance, inProgress } = useMsal()

    const handleLogin = (loginType) => {
      if (loginType === 'popup') {
        instance.logoutPopup({
          postLogoutRedirectUri: '/'
        })
      } else if (loginType === 'redirect') {
        instance.logoutRedirect({
          postLogoutRedirectUri: '/'
        })
      }
    }
    return (
      <PrimaryButton onClick={() => handleLogin('redirect')} disabled={inProgress === InteractionStatus.Logout}>
        Sign out
      </PrimaryButton>
    )
  }

  const HelloMessage = () => {
    const { accounts } = useMsal()
    const name = accounts[0] && accounts[0].name
    return <div>Hi {name}</div>
  }

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <AuthenticatedTemplate>
        <HelloMessage/>
        <MSSignOutButton/>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MSSignInButton/>
      </UnauthenticatedTemplate>

    </Stack>
  )
}
export default AccountStatus

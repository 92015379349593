import { addErrorHandler, addTokenHandler } from './general'

import axios from 'axios'
import config from '../config'

const instance = axios.create({
  baseURL: config.picadminMT
})

addErrorHandler(instance)
addTokenHandler(instance)

const getPilotFeatures = async () => {
  const response = await instance.get('Pilot/')
  return response.data
}

const createPilotFeature = async (requestBody) => {
  const response = await instance.post('Pilot/', requestBody)
  return response.data
}

const updatePilotFeature = async (id, requestBody) => {
  const response = await instance.post(`Pilot/${id}`, requestBody)
  return response.data
}

const getPilotFeature = async (id) => {
  const response = await instance.get(`Pilot/${id}`)
  return response.data
}

const getPilotFeatureUsers = async (id) => {
  const response = await instance.get(`Pilot/${id}/Domain`)
  return response.data
}

const addUsersForPilotFeature = async (id, requestBody) => {
  const response = await instance.post(`Pilot/${id}/Domain/Batch`, requestBody)
  return response.data
}

const removeUsersForPilotFeature = async (id, requestBody) => {
  const response = await instance.delete(`Pilot/${id}/Domain/Batch`, { data: requestBody })
  return response.data
}

const checkUserHasPilotFeature = async (id, domain) => {
  const response = await instance.get(`Pilot/${id}/Domain/${domain}/exists`)
  return response.data
}

export {
  getPilotFeatures,
  createPilotFeature,
  updatePilotFeature,
  getPilotFeature,
  getPilotFeatureUsers,
  addUsersForPilotFeature,
  removeUsersForPilotFeature,
  checkUserHasPilotFeature
}

import { createSlice } from '@reduxjs/toolkit'

export const querySlice = createSlice({
  name: 'query',
  initialState: {
    selectedQuery: null,
    activeTab: 0,
    personalQueryList: [],
    publicQueryList: [],
    favouriteQueryIds: []
  },

  reducers: {
    updatePersonalQueryList: (state, action) => {
      state.personalQueryList = action.payload
    },
    updatePublicQueryList: (state, action) => {
      state.publicQueryList = action.payload
    },
    updateFavouriteQueryIds: (state, action) => {
      state.favouriteQueryIds = action.payload
    },
    updateSelectedQuery: (state, action) => {
      state.selectedQuery = action.payload
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload
    }
  }
})

export const { updateFavouriteQueryIds, updatePersonalQueryList, updatePublicQueryList, updateSelectedQuery, updateActiveTab } = querySlice.actions
export default querySlice.reducer

import { DefaultButton, DetailsList, DetailsListLayoutMode, PrimaryButton, SelectionMode, Stack, TextField } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'

const columns = [
  {
    key: 'MerchantId',
    fieldName: 'MerchantId',
    name: 'Google Merchant Id',
    isResizable: true,
    minWidth: 160,
    maxWidth: 160
  },
  {
    key: 'Market',
    fieldName: 'Market',
    name: 'Market',
    isResizable: true,
    minWidth: 160,
    maxWidth: 160
  },
  {
    key: 'OfferCount',
    fieldName: 'OfferCount',
    name: 'Offer Count',
    isResizable: true,
    minWidth: 320,
    maxWidth: 320
  }
]

function InputData (props) {
  const [merchantOfferCountErrors, setMerchantOfferCountErrors] = React.useState([])
  const [providerIdError, setProviderIdError] = React.useState('')
  const [customerIdError, setCustomerIdError] = React.useState('')
  const [accountIdError, setAccountIdError] = React.useState('')
  const [partnerPrefixError, setPartnerPrefixError] = React.useState('')

  const onUploadFile = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = e.target.result
      const data = []
      const errorMsgs = []
      for (let lineIndex = 0, lineBeg = 0, lineEnd = -1; lineBeg < text.length; lineIndex += 1, lineBeg = lineEnd + 1) {
        lineEnd = text.indexOf('\n', lineBeg) >= 0 ? text.indexOf('\n', lineBeg) : text.length
        const line = text.substring(lineBeg, lineEnd).trim()
        if ((line.length === 0 || line[0] < '0' || line[0] > '9') && lineIndex === 0) { continue }
        if (!/^\d+,[a-z]{2}-[A-Z]{2},\d+,?$/g.test(line)) {
          errorMsgs.push(`line ${lineIndex + 1}:"${line}" is Invalid`)
        } else {
          const values = line.split(',')
          const MerchantId = Number(values[0]).toString()
          const Market = values[1]
          const OfferCount = Number(values[2])
          data.push({ MerchantId, Market, OfferCount })
        }
      }
      console.log(text)
      console.log(errorMsgs)
      console.log(data)
      props.setMerchantMarketOfferCount(data)
      setMerchantOfferCountErrors(errorMsgs)
    }
    reader.readAsText(e.target.files[0])
  }

  const isNumber = val => /^\d+$/g.test(val)

  const onProviderIdChange = (e) => {
    const val = e.target.value
    props.setProviderId(isNumber(val) ? Number(val) : 0)
    setProviderIdError(isNumber(val) ? '' : 'Provider Id should be a number.')
  }

  const onCustomerIdChange = (e) => {
    const val = e.target.value
    props.setCustomerId(isNumber(val) ? Number(val) : 0)
    setCustomerIdError(isNumber(val) ? '' : 'Customer Id should be a number.')
  }

  const onAccountIdChange = (e) => {
    const val = e.target.value
    props.setAccountId(isNumber(val) ? Number(val) : 0)
    setAccountIdError(isNumber(val) ? '' : 'Account Id should be a number.')
  }

  const onPartnerPrefixChange = (e) => {
    const val = e.target.value
    props.setDefaultPartnerPrefix(val)
    setPartnerPrefixError(val.includes('_') ? '"_" should not in Advertiser Prefix.' : '')
  }
  const isInputValid = props.providerId !== 0 && props.customerId !== 0 && props.accountId !== 0 && props.merchantMarketOfferCount.length > 0 && !props.defaultPartnerPrefix.includes('_')

  return (
    <div>
      <div>
        <h3 style={{ marginTop: '0px', marginBottom: '8px', fontSize: '24px', fontWeight: 800, color: 'red' }}> Attention! </h3>

        <ul>
          <li>This tool is only for refreshing google merchant list for onboarded customer.</li>
          <li>For enabling new customer, please contact IGS STCA team or create ICM ticket.</li>
        </ul>
        <h4>Limitation:</h4>
        <ul>
          <li>Schedule of Multi-Store-Import is forced to be daily.</li>
          <li>Schedule cannot be paused/resume in MMC UI. Customer need to esclate through support team if they want to pause the schedule.</li>
          <li>The number of de-DE & de-AT GMC stores should be limited to 5,000.</li>
        </ul>
        <h4>Onboard steps :</h4>
        <ol>
          <li>Get GMC stores information from customer. The information should contain &quot;GMC_Store_Id&quot;, &quot;Market&quot;, &quot;Estimate_Offer_Count&quot;.Example: &quot;132,pt-BR,456&quot;</li>
          <li>Check with varshb@microsoft.com (US market) or mayurarora@microsoft.com (INTL markets), make sure product advertising has enough capacity to serve those offers.</li>
          <li>Get store id of Microsoft merchant center which will be import to.</li>
          <li>Input the reuqired data and click &quot;Next Step&quot;.</li>
          <li>Click &quot;DryRun&quot; first and check the result.</li>
          <li>Click &quot;Execute&quot; if there is no warnings about the result of &quot;DryRun&quot;.</li>
        </ol>
        <h4>Onboard time cost:</h4>
          IIt will take 4 days to set up multiple store import and check offer download status after the GMC store information is ready and customer signed in GMC import.
      </div>
      <hr/>
      <Stack tokens={{ childrenGap: 8 }}>
        <h4>Multi Store Import Tool</h4>
        <TextField label="Provider Id(MMC Store Id)" onChange={onProviderIdChange} errorMessage={providerIdError} defaultValue={props.providerId }/>
        <TextField label="Customer Id" onChange={onCustomerIdChange} errorMessage={customerIdError} defaultValue={props.customerId }/>
        <TextField label="Account Id" onChange={onAccountIdChange} errorMessage={accountIdError} defaultValue={props.accountId }/>
        <TextField label="Default Advertiser Prefix" onChange={onPartnerPrefixChange} errorMessage={partnerPrefixError} defaultValue={props.defaultPartnerPrefix} placeholder='Name of customer. Used to name the catalog for enabling new customer. Leave it empty if refresh merchantIds.'/>
        <div style={{ marginTop: '8px' }}>
        Get GMC stores information (&lt;GMC_Store_Id, Market, Estimate_Offer_Count&gt; txt only) and upload the list by clicking button &quot;Choose File&quot;.
        </div>
        <input type='file' onChange={(e) => onUploadFile(e)}/>
        <div style={{ color: 'red' }}>
          { merchantOfferCountErrors
            .slice(0, 20)
            .map((item, i) =>
              <div key={i}>{item}</div>
            )}
        </div>
        <Stack tokens={{ childrenGap: 8 }} horizontal>
          <DefaultButton onClick={props.back} disabled={true}>Back</DefaultButton>
          <PrimaryButton onClick={props.nextStep} disabled={!isInputValid}>Next Step</PrimaryButton>
        </Stack>
        <DetailsList
          items={props.merchantMarketOfferCount}
          columns={columns}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
        />
      </Stack>
    </div>
  )
}
InputData.propTypes = {
  setProviderId: PropTypes.func.isRequired,
  providerId: PropTypes.number,
  setCustomerId: PropTypes.func.isRequired,
  customerId: PropTypes.number,
  setAccountId: PropTypes.func.isRequired,
  accountId: PropTypes.number,
  setMerchantMarketOfferCount: PropTypes.func.isRequired,
  merchantMarketOfferCount: PropTypes.array.isRequired,
  setDefaultPartnerPrefix: PropTypes.func.isRequired,
  defaultPartnerPrefix: PropTypes.string.isRequired,
  nextStep: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired
}

export default InputData

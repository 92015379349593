function exportFile (data, filename) {
  const urlObject = window.URL || window.webkitURL || window
  const exportBlob = new Blob([data])
  const saveLink = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
  saveLink.href = urlObject.createObjectURL(exportBlob)
  saveLink.download = filename
  saveLink.click()
}

function exportAsCSV (data, filename) {
  const lines = data.map((line, index) => line.join(','))
  const csvData = lines.join('\n')
  exportFile(csvData, filename)
}

function exportAsTXT (data, filename) {
  const lines = data.map((line, index) => line.join('\t'))
  const txtData = lines.join('\n')
  exportFile(txtData, filename)
}

function isValidString (s) {
  return s !== null && s !== undefined && typeof (s) === 'string' && s.length > 0
}
function getTenantName (tenantId) {
  switch (tenantId) {
    case 1: return 'Google'
    case 2: return 'Shopify'
    case 3: return 'Dynamics'
    case 4: return 'BigCommerce'
    case 5: return 'PrestaShop'
    case null: case undefined: return '-'
    default: return 'Others'
  }
}

function getFeedDownloadStatusName (statusId) {
  switch (statusId) {
    case 1: return 'ScheduleStart'
    case 2: return 'DownloadStart'
    case 3: return 'TransformStart'
    case 4: return 'WriteBlobStart'
    case 6: return 'Failed'
    case 20: return 'Failed_InternalError'
    case 21: return 'Failed_AuthorizationError'
    case 22: return 'Failed_NoMatchingOffers'
    case 23: return 'Failed_ExceedImportSizeLimit'
    case 24: return 'Failed_NotCapiEnabled'
    case 25: return 'Failed_ShopifyApiNotFound'
    case 26: return 'Failed_ShopifyCurrencyNotMatch'
    case null: case undefined: return '-'
    default: return 'Others'
  }
}
function getFeedImportStatusName (statusId) {
  switch (statusId) {
    case 0: return 'None'
    case 1: return 'Created'
    case 2: return 'ImportInProgress'
    case 3: return 'Imported'
    case 4: return 'ImportFailed'
    case null: case undefined: return '-'
    default: return 'Others'
  }
}

export default {
  exportFile,
  exportAsCSV,
  exportAsTXT,
  isValidString,
  getTenantName,
  getFeedDownloadStatusName,
  getFeedImportStatusName
}

import './asset/css/index.css'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { MsalProvider } from '@azure/msal-react'
import { Provider } from 'react-redux'
import { PublicClientApplication } from '@azure/msal-browser'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Router from './router'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { msalConfig } from './config/authConfig'
import reportWebVitals from './reportWebVitals'
import store from './store'

const queryClient = new QueryClient()

initializeIcons()
const msalInstance = new PublicClientApplication(msalConfig)
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store} >
        <QueryClientProvider client={queryClient}>
          <Router />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

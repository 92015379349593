import axios from 'axios'
import config from '../config'
import { addErrorHandler, addTokenHandler } from './general'

const instance = axios.create({
  baseURL: config.picadminMT
})

addErrorHandler(instance)
addTokenHandler(instance)

const getDownloadTaskMonitorData = async () => {
  const result = await instance.get(
    '/Monitor/GetDownloadTaskMonitorData'
  )
  return result.data
}

const getMerchantsWithSupport = async () => {
  const result = await instance.get(
    '/Monitor/GetMerchantsWithSupport'
  )
  return result.data
}

const upsertMerchantSupportEmail = async (providerId, supportEmails) => {
  const result = await instance.post(
    '/Monitor/UpsertMerchantSupportEmail',
    supportEmails,
    {
      params: {
        providerId
      }
    }
  )
  return result.data
}

const removeSupportEmail = async (supportEmail) => {
  const result = await instance.post(
    '/Monitor/RemoveSupportEmail',
    {},
    {
      params: {
        supportEmail
      }
    }
  )
  return result.data
}

export default {
  getDownloadTaskMonitorData,
  getMerchantsWithSupport,
  upsertMerchantSupportEmail,
  removeSupportEmail
}

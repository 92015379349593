import { PrimaryButton, Stack, TextField, Toggle } from '@fluentui/react'
import React, { useState } from 'react'
import googleService from '../../../service/google.service'
import AlertDialog from '../../../component/alertDialog'
import { useBoolean } from '@fluentui/react-hooks'
import { useMsal } from '@azure/msal-react'

const horizontalStackTokens = { childrenGap: 50 }
const verticalStackTokens = { childrenGap: 15 }
const successMessageStyle = { color: 'green' }
const failMessageStyle = { color: 'red' }
const providerIdLengthLimit = 16
const tokenInfoIdLengthLimit = 16
function ToolGMCStoreWhiteList () {
  const [providerId, setProviderId] = useState('')
  const [merchantIds, setMerchantIds] = useState('')
  const [merchantIdCount, setMerchantIdCount] = useState(0)
  const [skipStoreCountLimit, setSkipStoreCountLimit] = useState(false)
  const [tokenInfoId, setTokenInfoId] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [failMessage, setFailMessage] = useState('')
  const [hideAlertDialog, { toggle: toggleHideAlertDialog }] = useBoolean(true)

  const [providerIdError, setProviderIdError] = useState('')
  const [merchantIdsError, setMerchantIdsError] = useState('')
  const [tokenInfoIdError, setTokenInfoIdError] = useState('')
  const { accounts } = useMsal()
  console.log(accounts)

  function isNumber (value) {
    return /^\d*$/.test(value)
  }

  async function onAddWhiteList () {
    setSuccessMessage('')
    setFailMessage('')
    const merchantIdList = merchantIds.length > 0 ? merchantIds.match(/\d+/gm).map(item => Number(item)) : []
    const providerIdValid = isNumber(providerId) && providerId.length > 0
    const merchantIdsValid = merchantIdList.length > 0
    const tokenInfoIdValid = isNumber(tokenInfoId)
    const inputValid = providerIdValid && merchantIdsValid && tokenInfoIdValid
    if (inputValid) {
      try {
        const success = await googleService.addGoogleWhitelist(providerId, merchantIdList, skipStoreCountLimit, tokenInfoId)
        if (success) {
          setSuccessMessage('Success')
        } else {
          setFailMessage('Failed')
        }
      } catch (error) {
        setFailMessage(error.response.data.ExceptionMessage ?? 'Failed')
      }
    } else {
      if (!providerIdValid) setProviderIdError('Provider id is required and should be number ')
      if (!merchantIdsValid) setMerchantIdsError('Merchant Id is invalid')
      if (!tokenInfoIdValid) setTokenInfoIdError('Token Info Id is not number')
    }
  }

  function onProviderIdChange (e) {
    const value = e.target.value.substring(0, providerIdLengthLimit)
    setProviderId(value)
    setProviderIdError('')
  }

  function onMerchantIdsChange (e) {
    const val = e.target.value
    const merchantIdList = (val.match(/\d+/gm) ?? []).map(item => Number(item))
    setMerchantIdCount(merchantIdList.length)
    setMerchantIds(e.target.value)
    setMerchantIdsError('')
  }

  function onSkipStoreCountLimitChange () {
    setSkipStoreCountLimit(!skipStoreCountLimit)
  }

  function onTokenInfoIdChange (e) {
    const value = e.target.value.substring(0, tokenInfoIdLengthLimit)
    setTokenInfoId(value)
    setTokenInfoIdError('')
  }

  return (
    <div className='main-box' >
      <h1>Add Google Merchant Stores White List</h1>
      <hr/>
      <div>
        <h3>Why need to add google merchant store to white list</h3>
        <ul>
          <li>Make it easier and quicker to get details of google stores.</li>
        </ul>
        <h3>Attention:</h3>
        <ul>
          <li>The number of google stores need to be added should be limited to 600. </li>
          <li>After adding the goolge merchant stores to whitelist, Microsoft advertiser UI only shows the details of these ones. </li>
        </ul>
        <h3>Onboard steps :</h3>
        <ol>
          <li>Ask advertiser to provide MMC store Id (Provider Id) and google store Id list. </li>
          <li>Fill the MMC store Id and google store Id.</li>
          <li>Leave the &quot;Token Info Id &quot; empty and &quot;Skip Store Count Limit &quot; default setting.</li>
          <li>Click &quot;Excute&quot;.</li>
          <li>Ask customer to sign in GMC Import.</li>
        </ol>
      </div>
      <hr/>
      <h3>White List Adding Tool</h3>
      <Stack tokens={verticalStackTokens}>
        <Stack horizontal tokens={horizontalStackTokens} >
          <TextField label="MMC Store Id (Provider Id)" value={providerId} errorMessage={providerIdError} onChange={onProviderIdChange}/>
          <TextField label={merchantIds.length > 0 ? `${merchantIdCount} Google store Ids(Merchant Ids)` : 'Google store Ids(Merchant Ids)'} multiline rows={10} value={merchantIds} errorMessage={merchantIdsError} onChange={onMerchantIdsChange}/>
          <TextField label="Token Info Id (optional)" value={tokenInfoId} errorMessage={tokenInfoIdError} onChange={onTokenInfoIdChange}/>
          <Toggle label="Skip Store Count Limit" onText="Yes" offText="No" onChange={onSkipStoreCountLimitChange} />
        </Stack>
        <div>
          <PrimaryButton text="Excute" onClick={toggleHideAlertDialog} />
        </div>
        <div style={successMessageStyle}>{successMessage}</div>
        <div style={failMessageStyle}>{failMessage}</div>
      </Stack>

      <AlertDialog
        title='Warning'
        content={`Please Confirm you want to Add Google Merchant Stores White List of Provider Id "${providerId}"`}
        onConfirm={onAddWhiteList}
        hideDialog={hideAlertDialog}
        toggleHideDialog={toggleHideAlertDialog}
      />
    </div>
  )
}
export default ToolGMCStoreWhiteList

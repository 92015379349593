import * as React from 'react'
import { DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList'
import PropTypes from 'prop-types'

const columns = [
  {
    key: 'IssueType',
    fieldName: 'IssueType',
    name: 'Issue Type',
    minWidth: 48,
    maxWidth: 96,
    isResizable: true
  },
  {
    key: 'IssueCode',
    fieldName: 'IssueCode',
    name: 'Issue Code',
    minWidth: 128,
    maxWidth: 296,
    isResizable: true
  },
  {
    key: 'IssueMessage',
    fieldName: 'IssueMessage',
    name: 'Issue Message',
    minWidth: 128,
    maxWidth: 496,
    isResizable: true
  },
  {
    key: 'AffectedProductsCount',
    fieldName: 'AffectedProductsCount',
    name: 'Affected Products Count',
    minWidth: 48,
    maxWidth: 96,
    isResizable: true
  },
  {
    key: 'AffectedCountries',
    fieldName: 'AffectedCountries',
    name: 'Affected Countries',
    minWidth: 48,
    maxWidth: 240,
    isResizable: true
  }
]

export const ProductIssuesList = (props) => {
  let productIssuesData = props.productIssues?.value
  if (!productIssuesData || productIssuesData === []) {
    productIssuesData = [
      {
        IssueType: '\\',
        IssueCode: '\\',
        IssueMessage: '\\',
        AffectedProductsCount: '\\',
        AffectedCountries: '\\'
      }
    ]
  }
  return (
    <DetailsList
      items={productIssuesData}
      columns={columns}
      selectionMode={SelectionMode.none}
      setKey="none"
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
    />
  )
}

ProductIssuesList.propTypes = {
  productIssues: PropTypes.object,
  error: PropTypes.string
}

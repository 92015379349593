import axios from 'axios'
import config from '../config'
import { addErrorHandler, addTokenHandler } from './general'

const instance = axios.create({
  baseURL: config.picadminMT
})

addErrorHandler(instance)
addTokenHandler(instance)

const getAccountStatus = async (shopDomain) => {
  const response = await instance.get('ShopifyAppAdmin/GetAccountStatus', {
    params: {
      storeDomain: shopDomain
    }
  })
  return response.data
}

const getMmcStoreInfo = async (shopDomain) => {
  const response = await instance.get('ShopifyAppAdmin/GetMmcStoreInfo', {
    params: {
      storeDomain: shopDomain
    }
  })
  return response.data
}
const getShopifyStoreInfo = async (shopDomain) => {
  const response = await instance.get('ShopifyAppAdmin/GetShopifyStoreInfo', {
    params: {
      storeDomain: shopDomain
    }
  })
  return response.data
}

const GetOffersStatus = async (shopDomain) => {
  const response = await instance.get('ShopifyAppAdmin/GetOffersStatus', {
    params: {
      storeDomain: shopDomain
    }
  })
  return response.data
}

const GetCampaignInfo = async (shopDomain) => {
  const response = await instance.get('ShopifyAppAdmin/GetCampaignInfo', {
    params: {
      storeDomain: shopDomain
    }
  })
  return response.data
}

const UnBindShopify = async (shopifyDomain, customerId, accountId) => {
  const response = await instance.post('ShopifyAppAdmin/UnBindShopify', null, {
    params: {
      shopifyDomain,
      customerId,
      accountId
    }
  })
  return response.data
}

const GetProductIssues = async (storeDomain) => {
  const response = await instance.get('ShopifyAppAdmin/GetProductIssues', {
    params: {
      storeDomain
    }
  })
  return response.data
}

const getFeedDownloadInfo = async (storeDomain) => {
  const response = await instance.get('ShopifyAppAdmin/GetFeedDownloadInfo', {
    params: {
      storeDomain
    }
  })
  return response.data
}

const getFeedImportInfo = async (storeDomain) => {
  const response = await instance.get('ShopifyAppAdmin/GetFeedImportInfo', {
    params: {
      storeDomain
    }
  })
  return response.data
}

const createFeedSyncTask = async (catalogId) => {
  const result = await instance.post('/Products/CreateFeedSyncTask',
    { },
    {
      params: {
        CatalogId: catalogId
      }
    }
  )
  return result.data
}

export {
  getAccountStatus,
  getMmcStoreInfo,
  getShopifyStoreInfo,
  GetOffersStatus,
  GetCampaignInfo,
  UnBindShopify,
  GetProductIssues,
  getFeedDownloadInfo,
  getFeedImportInfo,
  createFeedSyncTask
}

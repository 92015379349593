import axios from 'axios'
import config from '../config'
import { addErrorHandler, addTokenHandler } from './general'

const instance = axios.create({
  baseURL: config.picadminMT
})

addErrorHandler(instance)
addTokenHandler(instance)

const getAccountStatus = async (storeHash) => {
  const response = await instance.get('bigcommerceappAdmin/GetAccountStatus', {
    params: {
      storeHash
    }
  })
  return response.data
}

const getMmcStoreInfo = async (storeHash) => {
  const response = await instance.get('bigcommerceappAdmin/GetMmcStoreInfo', {
    params: {
      storeHash
    }
  })
  return response.data
}

const UnBindBigCommerce = async (storeHash, customerId, accountId) => {
  const response = await instance.post('bigcommerceappAdmin/UnBindBigCommerce', null, {
    params: {
      storeHash,
      customerId,
      accountId
    }
  })
  return response.data
}

export {
  getAccountStatus,
  getMmcStoreInfo,
  UnBindBigCommerce
}

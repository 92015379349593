import axios from 'axios'
import config from '../config'
import { addErrorHandler, addTokenHandler } from './general'

const instance = axios.create({
  baseURL: config.picadminMT
})

addErrorHandler(instance)
addTokenHandler(instance)

const addGoogleWhitelist = async (providerId, merchantIdList, skipStoreCountLimit, tokenInfoId) => {
  const result = await instance.post('/GoogleImportAdmin/AddGmcStoreWhiteList',
    merchantIdList,
    {
      params: {
        providerId, skipStoreCountLimit, tokenInfoId
      }
    }
  )
  return result.data
}
const createFeedSyncTask = async (catalogId) => {
  const result = await instance.post('/Products/CreateFeedSyncTask',
    { },
    {
      params: {
        CatalogId: catalogId
      }
    }
  )
  return result.data
}

const checkToken = async (providerId, merchantId) => {
  const result = await instance.get('/GoogleImportAdmin/CheckToken', {
    params: {
      providerId,
      merchantId
    }
  }
  )
  return result.data
}

const getStoreScheduleInfo = async (providerId) => {
  const result = await instance.get('/GoogleImportAdmin/GetStoreScheduleInfo', {
    params: {
      providerId
    }
  }
  )
  return result.data
}

const getFeedDownloadInfo = async (providerId) => {
  const result = await instance.get('/GoogleImportAdmin/GetFeedDownloadInfo', {
    params: {
      providerId
    }
  }
  )
  return result.data
}

const getTokenInfo = async (providerId) => {
  const result = await instance.get('/GoogleImportAdmin/GetEnabledTokenInfo', {
    params: {
      providerId
    }
  }
  )
  return result.data
}

const getFeedImportInfo = async (providerId, catalogId) => {
  const result = await instance.get('/GoogleImportAdmin/GetFeedImportInfo', {
    params: {
      providerId,
      catalogId
    }
  }
  )
  return result.data
}

export default {
  addGoogleWhitelist,
  createFeedSyncTask,
  checkToken,
  getStoreScheduleInfo,
  getFeedDownloadInfo,
  getTokenInfo,
  getFeedImportInfo
}

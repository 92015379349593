import * as React from 'react'
import { DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList'
import PropTypes from 'prop-types'

const columns = [
  {
    key: 'CampaignId',
    fieldName: 'CampaignId',
    name: 'Campaign Id',
    minWidth: 160,
    maxWidth: 184,
    isResizable: true
  },
  {
    key: 'CampaignName',
    fieldName: 'CampaignName',
    name: 'Campaign Name',
    minWidth: 160,
    maxWidth: 240,
    isResizable: true
  },
  {
    key: 'CampaignStatus',
    fieldName: 'CampaignStatus',
    name: 'Campaign Status',
    minWidth: 160,
    maxWidth: 184,
    isResizable: true
  },
  {
    key: 'CampaignMarket',
    fieldName: 'CampaignMarket',
    name: 'Campaign Market',
    minWidth: 160,
    maxWidth: 184,
    isResizable: true
  },
  {
    key: 'DailyBudget',
    fieldName: 'DailyBudget',
    name: 'Daily Budget',
    minWidth: 160,
    maxWidth: 184,
    isResizable: true
  }
]

const isCompactMode = true

export const CampaignStatusList = (props) => {
  let campaignItems
  if (!props?.campaignStatus?.length) {
    campaignItems = [
      {
        CampaignId: '\\',
        CampaignName: '\\',
        CampaignStatus: '\\',
        CampaignMarket: '\\',
        DailyBudget: '\\'
      }
    ]
  } else {
    campaignItems = props.campaignStatus
  }
  return (
    <>
      <div className='shopify-title'>Campaign</div>
      <div className='error'>{props.error}</div>
      <DetailsList
        items={campaignItems}
        compact={isCompactMode}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </>

  )
}

CampaignStatusList.propTypes = {
  campaignStatus: PropTypes.array,
  error: PropTypes.string
}

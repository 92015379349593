import React from 'react'
import { DetailsList, IconButton, SelectionMode, SpinButton, Stack } from '@fluentui/react'
import PropType from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateActiveTab, updateFavouriteQueryIds, updatePersonalQueryList, updatePublicQueryList, updateSelectedQuery } from '../../../store/feature/querySlice'
import queryService from '../../../service/query.service'
import tab from '../model/tab'
import { useMsal } from '@azure/msal-react'

function QueryAction (props) {
  const dispatch = useDispatch()
  const { accounts } = useMsal()
  const userName = accounts[0] && accounts[0].username
  const favouriteIds = useSelector(state => state.query.favouriteQueryIds)
  const isOwner = props.queryInfo.Account === userName
  const isFavourite = favouriteIds.includes(props.queryInfo.Id)
  const isShared = props.queryInfo.Share
  function loadQueryList () {
    queryService
      .getPublicQueries()
      .then(data => dispatch(updatePublicQueryList(data)))
    queryService
      .getPersonalQueries(userName)
      .then(data => dispatch(updatePersonalQueryList(data)))
  }

  function onView () {
    dispatch(updateSelectedQuery(props.queryInfo))
    dispatch(updateActiveTab(tab.queryDetail))
  }

  async function onDelete () {
    await queryService.deleteQuery(props.queryInfo.Id)
    loadQueryList()
  }

  async function onAddFavourite () {
    await queryService.upsertFavouriteQuery(userName, props.queryInfo.Id, true)
    dispatch(updateFavouriteQueryIds([...favouriteIds, props.queryInfo.Id]))
  }

  async function onRemoveFavourite () {
    await queryService.upsertFavouriteQuery(userName, props.queryInfo.Id, false)
    dispatch(updateFavouriteQueryIds(favouriteIds.filter(item => item !== props.queryInfo.Id)))
  }

  async function onShare () {
    const requestBody = { ...props.queryInfo }
    requestBody.Share = true
    await queryService.upsertQuery(requestBody)
    loadQueryList()
  }
  async function onUnshare () {
    const requestBody = { ...props.queryInfo }
    requestBody.Share = false
    await queryService.upsertQuery(requestBody)
    loadQueryList()
  }

  return (
    <Stack horizontal tokens={{ childrenGap: 8 }}>
      <IconButton iconProps={{ iconName: 'View' }} title='View' onClick={onView}/>
      { isOwner && <IconButton iconProps={{ iconName: 'Delete' }} title='Delete' onClick={onDelete}/> }
      { isOwner && !isShared && <IconButton iconProps={{ iconName: 'Share' }} title='Share' onClick={onShare}/> }
      { isOwner && isShared && <IconButton iconProps={{ iconName: 'Lock' }} title='Unshare' onClick={onUnshare}/> }
      { !isOwner && !isFavourite && <IconButton iconProps={{ iconName: 'FavoriteStarFill' }} title='Add to Favorite' onClick={onAddFavourite}/>}
      { !isOwner && isFavourite && <IconButton iconProps={{ iconName: 'Unfavorite' }} title='Remove from Favorite' onClick={onRemoveFavourite}/>}
    </Stack>
  )
}
QueryAction.propTypes = {
  queryInfo: PropType.object.isRequired
}

function QueryList (props) {
  const [itemsPerPage, setItemsPerPage] = React.useState(15)
  const [currentItem, setCurrentItem] = React.useState(0)
  const columns = [
    { key: 0, name: 'QueryId', fieldName: 'queryId', maxWidth: 100 },
    { key: 1, name: 'Title', fieldName: 'title', minWidth: 100, flexGrow: 1 },
    { key: 2, name: 'Account', fieldName: 'account', minWidth: 240 },
    { key: 3, name: 'Action', fieldName: 'action', minWidth: 240 }
  ]

  const displayedItemsStart = Math.max(0, currentItem)
  const displayedItemsEnd = Math.min(props.data.length, currentItem + itemsPerPage)
  const displayedItems =
    props.data
      .slice(
        displayedItemsStart,
        displayedItemsEnd
      )
      .map((item, index) => {
        return {
          key: index,
          queryId: item.Id,
          title: item.Title,
          account: item.Account,
          action: <QueryAction queryInfo={item} />
        }
      })
  return (
    <div>
      <div className='flex-row'>
        <h3>{props.title}</h3>
        <div style={{ flexGrow: 1 }}/>
        <Stack>
          <SpinButton
            label="Items Per Page"
            onChange={(el, val) => {
              setItemsPerPage(Number(val))
            }}
            value={itemsPerPage}
            min={1}
            max={10000}
            step={1}
            incrementButtonAriaLabel="Increase value by 1"
            decrementButtonAriaLabel="Decrease value by 1"
            styles={{
              spinButtonWrapper: { width: 75 }
            }}
          />
        </Stack>
      </div>
      <DetailsList
        items={displayedItems}
        columns={columns}
        selectionMode={SelectionMode.none}
      />
      <div className='flex-row'>
        <IconButton iconProps={{ iconName: 'Previous' }} onClick={() => setCurrentItem(0)}/>
        <IconButton iconProps={{ iconName: 'ChevronLeft' }} onClick={() => setCurrentItem(Math.max(0, currentItem - itemsPerPage))}/>
        <IconButton iconProps={{ iconName: 'ChevronRight' }} onClick={() => setCurrentItem((currentItem + itemsPerPage >= props.data.length) ? currentItem : (currentItem + itemsPerPage))} />
        <IconButton iconProps={{ iconName: 'Next' }} onClick={() => setCurrentItem(Math.max(0, props.data.length - itemsPerPage))}/>
        <div style={{ flexGrow: 1 }}/>
        <div>{displayedItemsStart + 1}-{displayedItemsEnd} of {props.data.length} items</div>
      </div>
    </div>
  )
}
QueryList.propTypes = {
  title: PropType.string,
  data: PropType.array.isRequired
}
/*
QueryList.props.data =  [
    {
      queryId: number,
      title: string,
      ownerId: number,
      shared: bool,
      favourite: bool
    }
  ]
*/
export default QueryList

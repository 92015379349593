import AccountStatus from './component/accountStatus'
import MenuDownloaderMonitor from './component/menuDownloaderMonitor'
import MenuGMCTools from './component/menuTools'
import MenuInformation from './component/menuInfomation'
import MenuPilotManagement from './component/menuPilotManagement'
import MenuQuery from './component/menuQuery'
import MenuVIPMerchantMonitor from './component/menuVIPMerchantMonitor'
import React from 'react'
import config from '../../config'
import msIcon from '../../asset/img/ms-icon.png'

function Header () {
  return (
    <div className='header'>
      <div className='header-item'><img src={msIcon} alt='icon'/></div>
      <div className='header-item' style={{ marginRight: '32px' }}>PIC Admin {`[${config.env}]`}</div>
      <MenuQuery/>
      <MenuInformation />
      <MenuGMCTools/>
      <MenuDownloaderMonitor/>
      <MenuVIPMerchantMonitor/>
      <MenuPilotManagement/>
      <div className='header-item' style={{ flexGrow: 1 }}></div>
      <AccountStatus/>
    </div>
  )
}
export default Header

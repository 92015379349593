/* eslint-disable camelcase */
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
function ProviderId_TokenInfoId (props) {
  const columns = [
    {
      key: 'ProviderId',
      fieldName: 'ProviderId',
      name: 'ProviderId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'TokenInfoId',
      fieldName: 'TokenInfoId',
      name: 'TokenInfoId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    }
  ]
  const data = props.data
  return (
    <DetailsList
      items={data}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
    />
  )
}
ProviderId_TokenInfoId.propTypes = {
  data: PropTypes.array.isRequired
}
export default ProviderId_TokenInfoId

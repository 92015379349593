import * as React from 'react'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu'
import PropTypes from 'prop-types'

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu
}
const modalPropsStyles = { main: { maxWidth: 450 } }

const AlertDialog = (props) => {
  const [hideDialog, toggleHideDialog] = [props.hideDialog, props.toggleHideDialog]
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles,
      dragOptions
    }),
    [true]
  )

  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title ?? '',
    subText: props.content ?? ''
  }
  function onConfirm () {
    toggleHideDialog()
    if (props.onConfirm) {
      props.onConfirm()
    }
  }

  return (
    <>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={onConfirm} text="Confirm" />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  )
}

AlertDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onConfirm: PropTypes.func,
  hideDialog: PropTypes.bool.isRequired,
  toggleHideDialog: PropTypes.func.isRequired
}
AlertDialog.defaultProps = {
  title: '',
  content: '',
  onConfirm: () => null
}

export default AlertDialog

/* eslint-disable camelcase */
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
function DeletedGroupId (props) {
  const columns = [
    {
      key: 'Value',
      fieldName: 'Value',
      name: 'DeletedGroupId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    }
  ]
  return (
    <DetailsList
      items={props.data}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
    />
  )
}
DeletedGroupId.propTypes = {
  data: PropTypes.array.isRequired
}
export default DeletedGroupId

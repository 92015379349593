/* eslint-disable camelcase */
import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
import UpsertMultiStoreImportOfSingleMarket from './upsertMultiStoreImportOfSingleMarket'

function UpsertMultiStoreImport (props) {
  const [mixedMarket, setMixedMarket] = React.useState(null)
  const [marketList, setMarketList] = React.useState([])
  const [currentMarket, setCurrentMarket] = React.useState(0)

  React.useEffect(() => {
    setCurrentMarket(marketList[0])
  }, [marketList])

  React.useEffect(() => {
    const merchantIds = new Set()
    const markets = new Set()
    const duplicateMarketMerchantIds = new Set()

    props.merchantMarketOfferCount.forEach(item => {
      const merchantId = item.MerchantId
      const market = item.Market
      if (merchantIds.has(merchantId)) {
        duplicateMarketMerchantIds.add(merchantId)
      }
      merchantIds.add(merchantId)
      markets.add(market)
    })
    setMixedMarket(duplicateMarketMerchantIds.size * 2 >= merchantIds.size)
    setMarketList(Array.from(markets))
  }, [props.merchantMarketOfferCount])

  return (
    <Stack tokens={{ childrenGap: 4 }}>
      {
        mixedMarket === false &&
        <div>
          <h3 style={{ marginTop: '0px', marginBottom: '8px', fontSize: '24px', fontWeight: 800, color: 'red' }}> Note: </h3>
          <ul>
            <li>Do &quot;DryRun&quot; and &quot;Execute&quot; for each market.</li>
          </ul>
          <hr/>
        </div>
      }
      {
        mixedMarket === true &&
        <UpsertMultiStoreImportOfSingleMarket
          providerId={props.providerId}
          merchantMarketOfferCount={props.merchantMarketOfferCount}
          customerId={props.customerId}
          accountId={props.accountId}
          defaultPartnerPrefix={props.defaultPartnerPrefix}
          nextStep={() => null }
          back={() => null}
        />
      }
      {
        mixedMarket === false &&
        <>
          <h3>Market List:</h3>
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            { marketList.map(market => <DefaultButton key={market} checked={market === currentMarket} onClick={() => setCurrentMarket(market)}>{market}</DefaultButton>) }
          </Stack>

          <h3>Current Market: {currentMarket}</h3>
          {
            marketList.map((market, index) => (
              market === currentMarket &&
                <UpsertMultiStoreImportOfSingleMarket
                  key={market}
                  providerId={props.providerId}
                  merchantMarketOfferCount={props.merchantMarketOfferCount}
                  customerId={props.customerId}
                  accountId={props.accountId}
                  defaultPartnerPrefix={props.defaultPartnerPrefix}
                  market={market}
                  nextStep={() => setCurrentMarket(marketList[(index + 1) % marketList.length]) }
                  back={() => setCurrentMarket(marketList[(index + marketList.length - 1) % marketList.length]) }
                />
            ))
          }
        </>
      }

      <Stack horizontal tokens={{ childrenGap: 4 }}>
        <DefaultButton onClick={props.back}>Back</DefaultButton>
        <PrimaryButton onClick={props.nextStep} disabled>Next Step</PrimaryButton>
      </Stack>
    </Stack>
  )
}

UpsertMultiStoreImport.propTypes = {
  providerId: PropTypes.number.isRequired,
  customerId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  merchantMarketOfferCount: PropTypes.array.isRequired,
  defaultPartnerPrefix: PropTypes.string.isRequired
}

export default UpsertMultiStoreImport

import '../../../asset/css/pilotManagement.css'

import { Checkbox, DefaultButton, PrimaryButton, Shimmer, Stack, Text, TextField } from '@fluentui/react'
import React, { useState } from 'react'
import { getPilotFeature, updatePilotFeature } from '../../../service/pilot.service'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import PropTypes from 'prop-types'
import tenant from '../model/tenant'

function FeatureEditSection (props) {
  const [featureName, setFeatureName] = useState('')
  const [description, setDescription] = useState('')
  const [isGA, setIsGA] = useState(false)
  const [isEnabled, setIsEnabled] = useState(true)

  const [featureNameError, setFeatureNameError] = useState('')
  const [descriptionError, setDescriptionError] = useState('')

  const queryClient = useQueryClient()

  const { data: pilotFeatureData, isLoading: pilotFeatureLoading, isError: pilotFeatureError } = useQuery({
    queryKey: ['getPilotFeature', props.selectedId],
    queryFn: () => getPilotFeature(props.selectedId)
  })

  const updateFeatureMutation = useMutation({
    mutationFn: (updateFeatureRequest) => updatePilotFeature(props.selectedId, updateFeatureRequest),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getPilotFeatures')
      await queryClient.invalidateQueries(['getPilotFeature', props.selectedId])
      props.setIsEditFeatureDetail(false)
    },
    onError: (error) => {
      console.error(error)
      const errorCode = error.response.data.error.picApiPoolErrorCode
      if (errorCode === 'DuplicatePilotFeatureName') {
        setFeatureNameError('Feature name already exists, please use a different name')
      }
    }
  })

  const handleEditClick = () => {
    props.setIsEditFeatureDetail(true)
    setFeatureName(pilotFeatureData.name)
    setDescription(pilotFeatureData.description)
    setIsGA(pilotFeatureData.isGA)
    setIsEnabled(pilotFeatureData.isEnabled)
  }

  const handleFeatureNameChange = (e, value) => {
    setFeatureName(value)
    if (!value) {
      setFeatureNameError('Feature name is required')
    } else {
      setFeatureNameError('')
    }
  }

  const handleDescriptionChange = (e, value) => {
    setDescription(value)
    if (!value) {
      setDescriptionError('Description is required')
    } else {
      setDescriptionError('')
    }
  }

  const handleSubmit = () => {
    const updateFeatureRequest = {
      name: featureName.trim(),
      description: description.trim(),
      isGA,
      isEnabled
    }
    updateFeatureMutation.mutate(updateFeatureRequest)
  }

  const renderFeatureDetail = () => {
    if (pilotFeatureLoading || pilotFeatureError) {
      return (
        <Stack>
          <h3>Pilot Feature Details</h3>
          <Stack tokens={{ childrenGap: 8 }}>
            <Shimmer />
            <Shimmer />
            <Shimmer />
          </Stack>
        </Stack>
      )
    }
    return (
      <Stack>
        <Stack tokens={{ childrenGap: 48 }} horizontal verticalAlign='center'>
          <h2>Pilot Feature Details</h2>
          <PrimaryButton text="Edit" iconProps={{ iconName: 'Edit' }} onClick={handleEditClick} />
        </Stack>
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Feature Id:</p>
            <Text>{pilotFeatureData.id}</Text>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Tenant Id:</p>
            <Text>{`${pilotFeatureData.tenantId} _ [${tenant[pilotFeatureData.tenantId]}]`}</Text>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Feature Name:</p>
            <Text>{pilotFeatureData.name}</Text>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Description:</p>
            <Text>{pilotFeatureData.description}</Text>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Is GA:</p>
            <Text>{pilotFeatureData.isGA ? 'GA' : 'Not GA'}</Text>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Is Enabled:</p>
            <Text>{pilotFeatureData.isEnabled ? 'Enabled' : 'Disabled'}</Text>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  const renderEditFeatureDetail = () => {
    return (
      <Stack>
        <Stack tokens={{ childrenGap: 48 }} horizontal verticalAlign='center'>
          <h2>Pilot Feature Details</h2>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <PrimaryButton
              text="Save"
              iconProps={{ iconName: 'Save' }}
              onClick={handleSubmit}
              disabled={updateFeatureMutation.isPending || !featureName || !description}
            />
            <DefaultButton
              text="Cancel"
              iconProps={{ iconName: 'Cancel' }}
              onClick={() => props.setIsEditFeatureDetail(false)}
              disabled={updateFeatureMutation.isPending}
            />
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Feature Id:</p>
            <Text>{pilotFeatureData.id}</Text>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Tenant Id:</p>
            <Text>{`${pilotFeatureData.tenantId} _ [${tenant[pilotFeatureData.tenantId]}]`}</Text>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Feature Name:</p>
            <div style={{ width: '320px' }}>
              <TextField
                value={featureName}
                onChange={handleFeatureNameChange}
                errorMessage={featureNameError}
                disabled={updateFeatureMutation.isPending}
              />
            </div>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Description:</p>
            <div style={{ width: '320px' }}>
              <TextField
                value={description}
                onChange={handleDescriptionChange}
                errorMessage={descriptionError}
                multiline
                disabled={updateFeatureMutation.isPending}
              />
            </div>
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Is GA:</p>
            <Checkbox
              checked={isGA}
              onChange={(e, checked) => setIsGA(checked)}
              disabled={updateFeatureMutation.isPending}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign='center'>
            <p className='feature-details-text'>Is Enabled:</p>
            <Checkbox
              checked={isEnabled}
              onChange={(e, checked) => setIsEnabled(checked)}
              disabled={updateFeatureMutation.isPending}
            />
          </Stack>
        </Stack>
      </Stack>
    )
  }
  return (
    <>
      {props.isEditFeatureDetail ? renderEditFeatureDetail() : renderFeatureDetail()}
    </>
  )
}

FeatureEditSection.propTypes = {
  selectedId: PropTypes.number,
  isEditFeatureDetail: PropTypes.bool,
  setIsEditFeatureDetail: PropTypes.func
}

export default FeatureEditSection

import * as React from 'react'
import { DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList'
import PropTypes from 'prop-types'

const columns = [
  {
    key: 'MSAccountName',
    fieldName: 'MSAccountName',
    name: 'Microsoft Account Name',
    minWidth: 160,
    maxWidth: 232,
    isResizable: true
  },
  {
    key: 'AdsAccountName',
    fieldName: 'AdsAccountName',
    name: 'Microsoft Ads Account Name',
    minWidth: 184,
    maxWidth: 208,
    isResizable: true
  },
  {
    key: 'AccountID',
    fieldName: 'AccountID',
    name: 'Account ID',
    minWidth: 72,
    maxWidth: 88,
    isResizable: true
  },
  {
    key: 'CustomerID',
    fieldName: 'CustomerID',
    name: 'Customer ID',
    minWidth: 72,
    maxWidth: 112,
    isResizable: true
  },
  {
    key: 'AccountStatus',
    fieldName: 'AccountStatus',
    name: 'Account Status',
    minWidth: 120,
    maxWidth: 144,
    isResizable: true
  },
  {
    key: 'IsAdsTokenValid',
    fieldName: 'IsAdsTokenValid',
    name: 'Ads Token Status',
    minWidth: 160,
    maxWidth: 184,
    isResizable: true
  }
]

const formatAccountInfo = (accountInfo) => {
  Object.keys(accountInfo).forEach((key, index) => {
    if (accountInfo[key] === null || accountInfo[key] === 0) {
      accountInfo[key] = '\\'
    }
  })
}

const isCompactMode = true

export const AccountInfoList = (props) => {
  const accountInfo = props.accountInfo ?? {}
  formatAccountInfo(accountInfo)
  const items = [accountInfo]
  return (
    <>
      <div className='shopify-title'>Account</div>
      <div className='error'>{props.error}</div>
      <DetailsList
        items={items}
        compact={isCompactMode}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </>
  )
}

AccountInfoList.propTypes = {
  accountInfo: PropTypes.object,
  error: PropTypes.string
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import picadminQueryService from '../../../service/query.service'
import { updatePublicQueryList } from '../../../store/feature/querySlice'
import QueryList from './queryList'
function PublicQueryList (props) {
  const dispatch = useDispatch()
  const publicQueryList = useSelector(state => state.query.publicQueryList)

  async function loadPublicQueries () {
    const data = await picadminQueryService.getPublicQueries()
    dispatch(updatePublicQueryList(data))
  }

  useEffect(() => {
    loadPublicQueries()
  }, [])

  return (
    <QueryList
      title='Public Queries'
      data={publicQueryList}
    />
  )
}
export default PublicQueryList

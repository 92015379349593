export default {
  downloaderMonitor: '/downloaderMonitor',
  VIPMerchantMonitor: '/vipMerchantMonitor',
  query: '/query',
  shopify: '/',
  google: '/google',
  gmcToolWhiteList: '/gmcToolWhiteList',
  gmcToolMultiStoreImport: '/gmcToolMultiStoreImport',
  shopifyToolUnbindAdsAccount: '/shopifyToolUnbindAdsAccount',
  bigcommerceToolUnbindAdsAccount: '/bigcommerceToolUnbindAdsAccount',
  prestashopToolUnbindAdsAccount: '/prestashopToolUnbindAdsAccount',
  pilotManagement: '/pilotManagement'
}

import { FontIcon } from '@fluentui/react'
import React from 'react'

function Footer () {
  return (
    <div className='footer'>
      <div
        style={{ margin: '0 auto', background: 'white' }}
      >
        {' Go to '}
        <a
          href='https://msasg.visualstudio.com/Bing_Ads/_wiki/wikis/Bing_Ads.wiki/129556/Pic-Admin-Portal-Introduction'
          target='_blank'
          rel="noreferrer"
        >
          <FontIcon iconName='ClassNotebookLogo32' style={{ position: 'relative', top: 2 }}/>
          {' wiki '}
        </a>
        {' for portal introduction '}
      </div>
    </div>
  )
}
export default Footer

import { SearchBox } from '@fluentui/react/lib/SearchBox'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { Image } from '@fluentui/react/lib/Image'
import msSearchPage from '../../asset/img/ms-searchpage.png'
import React, { useState } from 'react'
import googleService from '../../service/google.service'
import { SkeletionPage } from './skeletonPage'
import MerchantInfo from './merchantInfo'
import CatalogInfo from './catalogInfo'

const cardStyle = {
  marginTop: '16px',
  padding: '16px',
  boxShadow: '2px 2px 2px 2px lightGray'
}

const GoogleInfo = () => {
  const [providerId, setProviderId] = useState(null)
  const [providerIdError, setProviderIdError] = useState('')
  const [loading, setLoading] = useState(false)
  const [feedDownloadInfo, setFeedDownloadInfo] = useState([])
  const [tokenInfo, setTokenInfo] = useState([])
  const [displayMsSearchPage, setDisplayMsSearchPage] = useState(true)

  const loadFeedDownloadInfo = async (providerId) => {
    const data = await googleService.getFeedDownloadInfo(providerId)
    setFeedDownloadInfo(data)
    return data
  }

  const loadTokenInfo = async (providerId) => {
    const data = await googleService.getTokenInfo(providerId)
    setTokenInfo(data)
    if (data.length === 0) {
      setProviderIdError(`No information found about ProviderId ${providerId}`)
    }
    return data
  }

  const loadProviderInfo = async (providerId) => {
    setProviderIdError('')
    if (!providerId || providerId === '') {
      setFeedDownloadInfo([])
      setTokenInfo([])
    } else {
      setLoading(true)
      Promise
        .all([loadFeedDownloadInfo(providerId), loadTokenInfo(providerId)])
        .finally(() => setLoading(false))
    }
  }

  const data = tokenInfo
    .map(tokenInfoItem => {
      return {
        tokenInfo: tokenInfoItem,
        feedDownloadInfo: feedDownloadInfo.filter(feedDownloadInfoItem => feedDownloadInfoItem.TokenInfoId === tokenInfoItem.Id)
      }
    })
    .filter(item => item.feedDownloadInfo.length > 0)

  const infoShow = data.map(item => {
    return (
      <div key={item.tokenInfo.Id} style={cardStyle}>
        <MerchantInfo tokenInfo={item.tokenInfo} feedDownloadInfo={item.feedDownloadInfo} providerId={providerId}/>
        <CatalogInfo tokenInfo={item.tokenInfo} feedDownloadInfo={item.feedDownloadInfo} providerId={providerId}/>
      </div>
    )
  })

  return (
    <div className='main-box ' >
      {displayMsSearchPage &&
      <Image
        src={ msSearchPage }
        alt="Example with no image fit value and height or width is specified."
        style = {{ margin: '120px auto 8px' }}
      >
      </Image>
      }
      <div style = {{ display: 'flex', margin: '0 auto', width: '70%' }}>
        <SearchBox placeholder="Please Enter Provider Id"
          styles = {{ root: { width: '100%' } }}
          onChange = {(_, providerId) => {
            setProviderId(providerId)
          }}
          onSearch = { (providerId) => {
            setProviderIdError('')
            loadProviderInfo(providerId)
            setDisplayMsSearchPage(!providerId || providerId === '')
          }}
        />
        <PrimaryButton text="Search"
          styles = {{ root: { marginLeft: '16px' } }}
          onClick={ () => {
            setProviderIdError('')
            loadProviderInfo(providerId)
            setDisplayMsSearchPage(!providerId || providerId === '')
          }} />
      </div>
      <div style = {{ display: 'flex', margin: '0 auto', width: '70%' }} className='error'>
        {providerIdError}
      </div>

      {!displayMsSearchPage &&
        loading
        ? <SkeletionPage />
        : infoShow }
    </div>
  )
}

export default GoogleInfo

import React from 'react'
import path from '../../../model/path'
import { useNavigate } from 'react-router'

function MenuQuery () {
  const navigate = useNavigate()
  function handleClickQueries () {
    navigate(path.query)
  }

  return (
    <div className='header-item clickable' onClick={handleClickQueries}>
        Queries
    </div>
  )
}
export default MenuQuery

import { PrimaryButton, Stack } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
import multiStoreImportService from '../../../service/multiStoreImport.service'
import MultiStoreImportInfo from './components/multiStoreImportInfo'
import JSZip from 'jszip'
import util from '../../../util'
import StatusName from './model/statusName'
import AlertDialog from '../../../component/alertDialog'
import { useBoolean } from '@fluentui/react-hooks'

function UpsertMultiStoreImportOfSingleMarket (props) {
  const [result, setResult] = React.useState({})
  const [errorMessages, setErrorMessages] = React.useState([])
  const [resultValid, setResultValid] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [finished, setFinished] = React.useState(false)
  const [multiStoreImportData, setMultiStoreImportData] = React.useState({})
  const [merchantOfferCount, setMerchantOfferCount] = React.useState([])
  const [merchantMarketOfferCount, setMerchantMarketOfferCount] = React.useState([])
  const [hideAlertDialog, { toggle: toggleHideAlertDialog }] = useBoolean(true)

  const loadMultiStoreImportData = async () => {
    try {
      setLoading(true)
      const data = await multiStoreImportService.retrieveMultiStoreImportData(props.providerId, props.customerId, props.accountId, props.market)
      setMultiStoreImportData(data)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    loadMultiStoreImportData()
  }, [props.providerId, props.customerId, props.accountId, props.market])

  React.useEffect(() => {
    const merchantMarketOfferCount = props.market ? props.merchantMarketOfferCount.filter(item => item.Market === props.market) : props.merchantMarketOfferCount
    let merchantOfferCount = {}
    merchantMarketOfferCount.forEach(item => {
      if (!merchantOfferCount[item.MerchantId]) {
        merchantOfferCount[item.MerchantId] = {
          MerchantId: item.MerchantId,
          OfferCount: item.OfferCount
        }
      } else {
        merchantOfferCount[item.MerchantId].OfferCount += item.OfferCount
      }
    })
    merchantOfferCount = Object.values(merchantOfferCount)
    setMerchantOfferCount(merchantOfferCount)
    setMerchantMarketOfferCount(merchantMarketOfferCount)
  }, [props.merchantMarketOfferCount, props.market])

  const checkResult = (result) => {
    setErrorMessages([])
    const newErrorMessages = []
    let valid = true
    if (result && result.Status_Count) {
      const statusCount = {}
      result.Status_Count.forEach(item => {
        statusCount[item.Status] = item.Count
      })
      if (statusCount[0] > 0) {
        newErrorMessages.push('Status Count of MovedToOhterCatalog > 0. Contact PIC team.')
        valid = false
      }
      if (statusCount[2] === 0) {
        newErrorMessages.push('Merchantids are totally different. Please confirm with support team / advertisers if this is expected.')
      }
    } if (Array.isArray(result?.LogList)) {
      if (result.LogList.length > 0) {
        newErrorMessages.push('Server Log is not empty.')
        valid = false
      } else {
        valid = true
      }
    } else {
      valid = false
    }
    setResultValid(valid)
    setErrorMessages([...newErrorMessages])
    return valid
  }

  const saveAllFiles = (multiStoreImportData, upsertResult) => {
    const zip = new JSZip()
    // input
    zip.file(
      'MerchantId_Market_OfferCount.txt',
      merchantMarketOfferCount
        .map(item => `${item.MerchantId}\t${item.Market}\t${item.OfferCount}`)
        .join('\n')
    )

    zip.file(
      'MerchantId_OfferCount.txt',
      merchantOfferCount
        .map(item => `${item.MerchantId}\t${item.OfferCount}`)
        .join('\n')
    )

    if (multiStoreImportData.CatalogId_CatalogName) {
      // multistore import data
      zip.file(
        'CatalogId_CatalogName.txt',
        multiStoreImportData
          .CatalogId_CatalogName
          .map(item => `${item.CatalogId}\t${item.CatalogName}`)
          .join('\n')
      )
      zip.file(
        'AllImportCatalogIds.txt',
        multiStoreImportData.AllImportCatalogIds.join('\n')
      )
      zip.file(
        'CatalogId_Market_GroupId_CatalogMerchantIds.txt',
        multiStoreImportData
          .CatalogId_Market_GroupId_CatalogMerchantIds
          .map(item => `${item.CatalogId}\t${item.Market}\t${item.GroupId}\t${item.CatalogMerchantIds.join(',')}`)
          .join('\n')
      )
      zip.file(
        'GroupId_MerchantIds.txt',
        multiStoreImportData
          .GroupId_MerchantIds
          .map(item => `${item.GroupId}\t${item.MerchantIds.join(',')}`)
          .join('\n')
      )
      zip.file(
        'ProviderId_TokenInfoId.txt',
        multiStoreImportData
          .ProviderId_TokenInfoId
          .map(item => `${item.ProviderId}\t${item.TokenInfoId}`)
          .join('\n')
      )
    }

    if (upsertResult.OldGroupId_MerchantIds_OfferCount) {
      // RefreshMerchantGroups
      zip.file(
        'OldGroupId_MerchantIds_OfferCount.txt',
        upsertResult
          .OldGroupId_MerchantIds_OfferCount
          .map(item => `${item.OldGroupId}\t${item.MerchantIds.join(',')}\t${item.OfferCount}`)
          .join('\n')
      )
      zip.file(
        'NewGroupId_MerchantIds_OfferCount.txt',
        upsertResult
          .NewGroupId_MerchantIds_OfferCount
          .map(item => `${item.NewGroupId}\t${item.MerchantIds.join(',')}\t${item.OfferCount}`)
          .join('\n')
      )
      zip.file(
        'DeletedGroupId.txt',
        upsertResult
          .DeletedGroupId
          .map(item => `${item.Value}`)
          .join('\n')
      )
      zip.file(
        'GroupId_MerchantIdCount_OfferCount.txt',
        upsertResult
          .GroupId_MerchantIdCount_OfferCount
          .map(item => `${item.GroupId}\t${item.MerchantIdCount}\t${item.OfferCount}`)
          .join('\n')
      )

      // RefreshMerchantGroupCatalogs
      zip.file(
        'OldCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount.txt',
        upsertResult
          .OldCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount
          .map(item => `${item.OldCatalogId}\t${item.Market}\t${item.GroupId}\t${item.CatalogMerchantIds.join(',')}\t${item.OfferCount}`)
          .join('\n')
      )
      zip.file(
        'NewCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount.txt',
        upsertResult
          .NewCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount
          .map(item => `${item.NewCatalogId}\t${item.Market}\t${item.GroupId}\t${item.CatalogMerchantIds.join(',')}\t${item.OfferCount}`)
          .join('\n')
      )
      zip.file(
        'DeletedCatalogId_Market_GroupId_CatalogMerchantIds.txt',
        upsertResult
          .DeletedCatalogId_Market_GroupId_CatalogMerchantIds
          .map(item => `${item.DeletedCatalogId}\t${item.Market}\t${item.GroupId}\t${item.CatalogMerchantIds.join(',')}`)
          .join('\n')
      )

      // CreateGImportCatalogs
      zip.file(
        'NewCatalogId_NewCatalogName_Market_CatalogMerchantIds_GroupId.txt',
        upsertResult
          .NewCatalogId_NewCatalogName_Market_CatalogMerchantIds_GroupId
          .map(item => `${item.NewCatalogId}\t${item.NewCatalogName}\t${item.Market}\t${item.CatalogMerchantIds.join(',')}\t${item.GroupId}`)
          .join('\n')
      )
      zip.file(
        'CreateCatalogLog.txt',
        upsertResult
          .CreateCatalogLog
          .map(item => `${item.ProviderId}\t${item.CatalogName}\t${item.CatalogId}\t${item.BmcSuccess}\t${item.GImportSuccess}\t${item.ErrorMessage}`)
          .join('\n')
      )

      // GenerateSQLUpdateScript
      zip.file(
        'SQL_UpdateOldGroup.txt',
        upsertResult
          .UpdateOldGroupSQL
          .join('\n')
      )
      zip.file(
        'SQL_AddNewGroup.txt',
        upsertResult
          .AddNewGroupSQL
          .join('\n')
      )
      zip.file(
        'MerchantId_Market_OldCatalogId_NewCatalogId_Status.txt',
        upsertResult
          .MerchantId_Market_OldCatalogId_NewCatalogId_Status
          .map(item => `${item.MerchantId}\t${item.Market}\t${item.OldCatalogId}\t${item.NewCatalogId}\t${item.Status}`)
          .join('\n')
      )
      zip.file(
        'Status_Count.txt',
        upsertResult
          .Status_Count
          .map(item => `${StatusName[item.Status]}\t${item.Count}`)
          .join('\n')
      )
    }
    zip.generateAsync({ type: 'blob' }).then((blob) => util.exportFile(blob, `${props.defaultPartnerPrefix}_${props.market ?? 'mixedMarket'}.zip`))
  }

  const enableOrRefreshMultiStoreImport = async (dryRun) => {
    try {
      setLoading(true)
      const data = await multiStoreImportService.retrieveMultiStoreImportData(props.providerId, props.customerId, props.accountId, props.market)
      setMultiStoreImportData(data)
      const requestBody = {}
      requestBody.CatalogId_Market_GroupId_CatalogMerchantIds = data.CatalogId_Market_GroupId_CatalogMerchantIds
      requestBody.GroupId_MerchantIds = data.GroupId_MerchantIds
      requestBody.ProviderId_TokenInfoId = data.ProviderId_TokenInfoId
      requestBody.AllImportCatalogIds = data.AllImportCatalogIds
      requestBody.MerchantId_Market_OfferCount = merchantMarketOfferCount
      requestBody.MerchantId_OfferCount = merchantOfferCount
      requestBody.CatalogId_CatalogName = data.CatalogId_CatalogName
      requestBody.DryRun = dryRun !== false
      requestBody.CustomerId = props.customerId
      requestBody.AccountId = props.accountId
      requestBody.DefaultPartnerPrefix = props.defaultPartnerPrefix

      const responseBody = await multiStoreImportService.upsertMultiStoreImport(requestBody)
      const valid = checkResult(responseBody)
      setResult(responseBody)
      if (!dryRun) {
        setFinished(true)
        saveAllFiles(data, responseBody)
      }
      alert(valid ? 'success' : 'failed')
    } catch (error) {
      console.error(error)
      alert((error.response && error.response.data.ExceptionMessage) || error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <div>
      <Stack tokens={{ childrenGap: 4 }}>
        <Stack tokens={{ childrenGap: 4 }}>
          <PrimaryButton onClick={() => enableOrRefreshMultiStoreImport(true)} disabled={loading || !multiStoreImportData.AllImportCatalogIds}>DryRun</PrimaryButton>
          <PrimaryButton onClick={toggleHideAlertDialog} disabled={loading || finished || !resultValid}>Execute</PrimaryButton>
          <PrimaryButton onClick={() => saveAllFiles(multiStoreImportData, result)} disabled={loading}>Export result(If the file is not downloaded automaticly)</PrimaryButton>
        </Stack>
        <div style={{ color: 'red' }}>
          {errorMessages.map((item, index) => <div key={index}>{item}</div>)}
        </div>
        <MultiStoreImportInfo result={result} multiStoreImportData={multiStoreImportData} />
      </Stack>

      <AlertDialog
        title='Warning'
        content={`Please Confirm you want to Executr GMC Multi Store Import Tool for ProviderId ${props.providerId}`}
        onConfirm={() => enableOrRefreshMultiStoreImport(false)}
        hideDialog={hideAlertDialog}
        toggleHideDialog={toggleHideAlertDialog}
      />
    </div>
  )
}
UpsertMultiStoreImportOfSingleMarket.propTypes = {
  providerId: PropTypes.number.isRequired,
  customerId: PropTypes.number.isRequired,
  accountId: PropTypes.number.isRequired,
  nextStep: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  merchantMarketOfferCount: PropTypes.array.isRequired,
  defaultPartnerPrefix: PropTypes.string.isRequired,
  market: PropTypes.string
}

export default UpsertMultiStoreImportOfSingleMarket

import * as React from 'react'
import { DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList'
import { mmcStoreStatus } from '../../model/mmcStoreStatus'
import PropTypes from 'prop-types'

const columns = [
  {
    key: 'MmcStoreName',
    fieldName: 'MmcStoreName',
    name: 'Merchant Center Store Name',
    minWidth: 200,
    maxWidth: 216,
    isResizable: true
  },
  {
    key: 'ProviderID',
    fieldName: 'ProviderID',
    name: ' Provider ID',
    minWidth: 72,
    maxWidth: 88,
    isResizable: true
  },
  {
    key: 'IsFraud',
    fieldName: 'IsFraud',
    name: 'Merchant Center Store Status',
    minWidth: 200,
    maxWidth: 216,
    isResizable: true
  },
  {
    key: 'IsDownloaderEnabled',
    fieldName: 'IsDownloaderEnabled',
    name: 'Downloader Status',
    minWidth: 120,
    maxWidth: 186,
    isResizable: true
  },
  {
    key: 'isCreatedByShopify',
    fieldName: 'isCreatedByShopify',
    name: 'Created By Shopify',
    minWidth: 120,
    maxWidth: 192,
    isResizable: true
  }
]
const isCompactMode = true

export const MmcInfoList = (props) => {
  let mmcStoreTransfer = props.mmcInfo
  if (!mmcStoreTransfer?.MmcStoreName) {
    mmcStoreTransfer = {
      MmcStoreName: '\\',
      ProviderID: '\\',
      IsFraud: '\\',
      IsDownloaderEnabled: '\\',
      isCreatedByShopify: '\\'
    }
  } else if (mmcStoreTransfer.IsFraud !== mmcStoreStatus.DISAPPROVED && mmcStoreTransfer.IsFraud !== mmcStoreStatus.APPROVED) {
    mmcStoreTransfer.IsFraud = mmcStoreTransfer.IsFraud ? mmcStoreStatus.DISAPPROVED : mmcStoreStatus.APPROVED
  }
  const items = [mmcStoreTransfer]
  return (
    <>
      <div className='shopify-title'>Merchant Center Store</div>
      <div className='error'>{props.error}</div>
      <DetailsList
        items={items}
        compact={isCompactMode}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </>
  )
}

MmcInfoList.propTypes = {
  mmcInfo: PropTypes.object,
  error: PropTypes.string
}

/* eslint-disable camelcase */
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
function GroupId_MerchantIdCount_OfferCount (props) {
  const columns = [
    {
      key: 'GroupId',
      fieldName: 'GroupId',
      name: 'GroupId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'MerchantIdCount',
      fieldName: 'MerchantIdCount',
      name: 'MerchantIdCount',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'OfferCount',
      fieldName: 'OfferCount',
      name: 'OfferCount',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    }
  ]
  return (
    <DetailsList
      items={props.data}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
    />
  )
}
GroupId_MerchantIdCount_OfferCount.propTypes = {
  data: PropTypes.array.isRequired
}
export default GroupId_MerchantIdCount_OfferCount

import * as React from 'react'
import { Shimmer, mergeStyles, ThemeProvider } from '@fluentui/react'

const wrapperClass = mergeStyles({
  padding: 2,
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '10px 0'
    }
  }
})

export const SkeletonCard = () => {
  return (
    <ThemeProvider className={wrapperClass}>
      <Shimmer width="20%" />
      <Shimmer />
      <Shimmer width="75%" />
      <Shimmer width="50%" />
      <Shimmer width="20%" />
      <Shimmer />
      <Shimmer width="75%" />
      <Shimmer width="50%" />
      <Shimmer width="20%" />
      <Shimmer />
      <Shimmer width="75%" />
      <Shimmer width="50%" />
      <Shimmer width="20%" />
      <Shimmer />
      <Shimmer width="75%" />
      <Shimmer width="50%" />
      <Shimmer width="20%" />
      <Shimmer />
      <Shimmer width="75%" />
      <Shimmer width="50%" />
    </ThemeProvider>
  )
}

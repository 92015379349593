import React from 'react'
import path from '../../../model/path'
import { useNavigate } from 'react-router'

function MenuVIPMerchantMonitor () {
  const navigate = useNavigate()
  function handleClickHome () {
    navigate(path.VIPMerchantMonitor)
  }
  return (
    <div className='header-item clickable' onClick={handleClickHome}>
        VIPMerchantMonitor
    </div>
  )
}
export default MenuVIPMerchantMonitor

import * as React from 'react'
import InputData from './inputData'
import UpsertMultiStoreImport from './upsertMultiStoreImport'

function ToolGMCMultiStoreImport () {
  const [providerId, setProviderId] = React.useState(null)
  const [customerId, setCustomerId] = React.useState(null)
  const [accountId, setAccountId] = React.useState(null)
  const [defaultPartnerPrefix, setDefaultPartnerPrefix] = React.useState('')
  const [merchantMarketOfferCount, setMerchantMarketOfferCount] = React.useState([])
  const [step, setStep] = React.useState(0)

  return (
    <div className='main-box' >
      <h1>GMC Multi Store Import</h1>
      <hr/>
      {
        step === 0 &&
        <>
          <InputData
            setProviderId={setProviderId}
            providerId={providerId}
            setCustomerId={setCustomerId}
            customerId={customerId}
            setAccountId={setAccountId}
            accountId={accountId}
            setMerchantMarketOfferCount={setMerchantMarketOfferCount}
            merchantMarketOfferCount={merchantMarketOfferCount}
            setDefaultPartnerPrefix={setDefaultPartnerPrefix}
            defaultPartnerPrefix={defaultPartnerPrefix}
            nextStep={() => setStep(1) }
            back={() => null}
          />
        </>
      }
      {
        step === 1 &&
        <>
          <UpsertMultiStoreImport
            providerId={providerId}
            merchantMarketOfferCount={merchantMarketOfferCount}
            customerId={customerId}
            accountId={accountId}
            defaultPartnerPrefix={defaultPartnerPrefix}
            nextStep={() => setStep(1) }
            back={() => setStep(0)}
          />
        </>
      }

    </div>
  )
}

export default ToolGMCMultiStoreImport

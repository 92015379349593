import '../../../asset/css/pilotManagement.css'

import { ActionButton, Checkbox, Dropdown, Modal, PrimaryButton, Stack, TextField } from '@fluentui/react'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import PropTypes from 'prop-types'
import { createPilotFeature } from '../../../service/pilot.service'
import tenant from '../model/tenant'

function FeatureCreateModal (props) {
  const [featureName, setFeatureName] = useState('')
  const [description, setDescription] = useState('')
  const [tenantId, setTenantId] = useState(null)
  const [isGA, setIsGA] = useState(false)
  const [isEnabled, setIsEnabled] = useState(true)

  const [featureNameError, setFeatureNameError] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [tenantIdError, setTenantIdError] = useState('')

  const queryClient = useQueryClient()

  const createFeatureMutation = useMutation({
    mutationFn: (createFeatureRequest) => createPilotFeature(createFeatureRequest),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getPilotFeatures')
      props.onClose()
    },
    onError: (error) => {
      console.error(error)
      const errorCode = error.response.data.error.picApiPoolErrorCode
      if (errorCode === 'DuplicatePilotFeatureName') {
        setFeatureNameError('Feature name already exists, please use a different name')
      }
    }
  })

  const handleFeatureNameChange = (e, value) => {
    setFeatureName(value)
    if (!value) {
      setFeatureNameError('Feature name is required')
    } else {
      setFeatureNameError('')
    }
  }

  const handleDescriptionChange = (e, value) => {
    setDescription(value)
    if (!value) {
      setDescriptionError('Description is required')
    } else {
      setDescriptionError('')
    }
  }

  const handleTenantIdChange = (e, item) => {
    setTenantId(item.key)
    if (!item.key) {
      setTenantIdError('Tenant is required')
    } else {
      setTenantIdError('')
    }
  }

  const checkSubmit = () => {
    return featureName && description && tenantId &&
    featureNameError === '' && descriptionError === '' && tenantIdError === ''
  }

  const handleSubmit = () => {
    const createFeatureRequest = {
      name: featureName.trim(),
      description: description.trim(),
      tenantId,
      isGA,
      isEnabled
    }
    createFeatureMutation.mutate(createFeatureRequest)
  }

  const handleDismiss = () => {
    if (createFeatureMutation.isPending) {
      return
    }
    setFeatureName('')
    setDescription('')
    setTenantId(null)
    setIsGA(false)
    setIsEnabled(true)
    setFeatureNameError('')
    setDescriptionError('')
    setTenantIdError('')
    props.onClose()
  }

  return (
    <Modal
      titleAriaId='featureCreateModal'
      isOpen={props.isOpen}
      onDismiss={handleDismiss}
    >
      <div className='create-modal-container'>
        <div className='create-modal-header'>
          <h2 className='create-modal-heading' id='featureCreateModal'>
            Create Pilot Feature
          </h2>
        </div>
        <div className='create-modal-body'>
          <Stack tokens={{ childrenGap: 8 }}>
            <Stack tokens={{ childrenGap: 16 }}>
              <Stack tokens={{ childrenGap: 8 }}>
                <TextField
                  label='Feature Name'
                  value={featureName}
                  onChange={handleFeatureNameChange}
                  errorMessage={featureNameError}
                  disabled={createFeatureMutation.isPending}
                />
                <TextField
                  label='Description'
                  value={description}
                  onChange={handleDescriptionChange}
                  errorMessage={descriptionError}
                  multiline rows={3}
                  disabled={createFeatureMutation.isPending}
                />
                <Dropdown
                  label='Tenant'
                  options={Object.keys(tenant).map(key => ({ key, text: tenant[key] }))}
                  selectedKey={tenantId}
                  onChange={handleTenantIdChange}
                  errorMessage={tenantIdError}
                  disabled={createFeatureMutation.isPending}
                />
              </Stack>
              <Stack tokens={{ childrenGap: 16 }}>
                <Checkbox
                  label='Is GA'
                  checked={isGA}
                  onChange={(e, checked) => setIsGA(checked)}
                  disabled={createFeatureMutation.isPending}
                />
                <Checkbox
                  label='Is Enabled'
                  checked={isEnabled}
                  onChange={(e, checked) => setIsEnabled(checked)}
                  disabled={createFeatureMutation.isPending}
                />
              </Stack>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 8 }} horizontalAlign='end' verticalAlign='center'>
              <PrimaryButton
                text='Create'
                onClick={handleSubmit}
                disabled={!checkSubmit() || createFeatureMutation.isPending}
              />
              <ActionButton text='Cancel' onClick={handleDismiss} disabled={createFeatureMutation.isPending}/>
            </Stack>
          </Stack>
        </div>
      </div>
    </Modal>
  )
}

FeatureCreateModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default FeatureCreateModal

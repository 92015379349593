import React from 'react'
import path from '../../../model/path'
import { useNavigate } from 'react-router'

function MenuDownloaderMonitor () {
  const navigate = useNavigate()
  function handleClickHome () {
    navigate(path.downloaderMonitor)
  }
  return (
    <div className='header-item clickable' onClick={handleClickHome}>
        DownloaderMonitor
    </div>
  )
}
export default MenuDownloaderMonitor

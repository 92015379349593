/* eslint-disable no-unused-vars */
import { PrimaryButton, Stack, TextField, Label } from '@fluentui/react'
import React, { useState } from 'react'
import queryService from '../../../service/query.service'
import util from '../../../util'
import QueryResultList from './queryResultList'
import PropTypes from 'prop-types'
import { useMsal } from '@azure/msal-react'
import Editor from '@monaco-editor/react'

function QueryDetail (props) {
  const { accounts } = useMsal()
  const userName = accounts[0] && accounts[0].username
  const [title, setTitle] = useState(props.queryInfo.Title)
  const [titleErrorMsg, setTitleErrorMsg] = useState()
  const [description, setDescription] = useState(props.queryInfo.Description)
  const [sql, setSql] = useState(props.queryInfo.SQL)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [results, setResults] = useState([])
  const isOwner = props.queryInfo.Account === userName

  function exportAs (type) {
    const exportData = []
    exportData.push(Object.keys(results[0]))
    results.forEach((item, index) => {
      exportData.push(Object.values(item))
    })
    if (type === 'csv') util.exportAsCSV(exportData, 'results.csv')
    if (type === 'txt') util.exportAsTXT(exportData, 'results.txt')
  }

  async function onExecute () {
    try {
      setLoading(true)
      const _results = await queryService.executeQuery(sql)
      console.log(_results)

      const columns = _results.Columns
      const data = _results.Data

      setResults(data.map(item => {
        const _item = {}
        for (let i = 0; i < columns.length; i += 1) {
          _item[columns[i]] = item[i]
        }
        return _item
      }))
      setErrorMessage('')
    } catch (error) {
      try {
        setErrorMessage(error.response.data.InnerException.ExceptionMessage)
      } catch (_) {
        console.error(error)
        setErrorMessage('Unknow error')
      }
    } finally {
      setLoading(false)
    }
  }

  async function onSave () {
    try {
      if (!title) {
        setTitleErrorMsg('Title is required')
        return
      }
      setLoading(true)
      const requestBody = {
        Id: props.queryInfo.Id,
        Title: title,
        Description: description,
        SQL: sql,
        Account: userName,
        Share: props.queryInfo.Share
      }
      await queryService.upsertQuery(requestBody)
    } finally {
      setLoading(false)
    }
  }

  async function onSaveAsNew () {
    try {
      if (!title) {
        setTitleErrorMsg('Title is required')
        return
      }
      setLoading(true)
      const requestBody = {
        Title: title,
        Description: description,
        SQL: sql,
        Account: userName,
        Share: false
      }
      await queryService.upsertQuery(requestBody)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <TextField label="Title" onChange={(e, newVal) => { setTitle(newVal); setTitleErrorMsg('') }} errorMessage={titleErrorMsg} disabled={loading} value={title} required={true}/>
      <TextField label="Description" onChange={(e, newVal) => setDescription(newVal)} disabled={loading} value={description}/>
      <Label>Content</Label>
      <div style={{ border: '1px solid #333', padding: '8px 0' }}>
        <Editor height="400px" defaultLanguage="sql" onChange={(value) => setSql(value)} value={sql}/>
      </div>
      <Stack horizontal tokens={{ childrenGap: 40 }}>
        <PrimaryButton text="Execute" onClick={onExecute} disabled={loading}/>
        {isOwner && <PrimaryButton text="Save" onClick={onSave} disabled={loading}/>}
        <PrimaryButton text="Save as New" onClick={onSaveAsNew} disabled={loading}/>
      </Stack>
      <div style={{ color: 'red' }}>{errorMessage}</div>
      <hr/>
      <QueryResultList data={results}/>
      <Stack horizontal tokens={{ childrenGap: 40 }}>
        <PrimaryButton text="Export as CSV" onClick={() => exportAs('csv')} disabled={loading}/>
        <PrimaryButton text="Export as TXT" onClick={() => exportAs('txt')} disabled={loading}/>
      </Stack>
    </>
  )
}

QueryDetail.propTypes = {
  queryInfo: PropTypes.object.isRequired
}
QueryDetail.defaultProps = {
  queryInfo: {
    Description: '',
    SQL: '',
    Share: false,
    Title: ''
  }
}
export default QueryDetail

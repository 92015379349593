/* eslint-disable camelcase */
import { TextField } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'

function LogList (props) {
  return (
    <TextField label='Server Log' value={props.data.join('\n')} readOnly multiline autoAdjustHeight/>
  )
}
LogList.propTypes = {
  data: PropTypes.array.isRequired
}
export default LogList

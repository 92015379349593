import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { loginRequest } from '../config/authConfig'
let msalContext = {}

const setMsalContext = (val) => { msalContext = val }

const addErrorHandler = (instance) => {
  instance.interceptors.response.use((response) => {
    return response
  }, (error) => {
    return Promise.reject(error)
  })
}

const addTokenHandler = (axiosInstance) => {
  axiosInstance
    .interceptors
    .request
    .use(async function (config) {
      const { instance, accounts } = msalContext
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0]
      }
      try {
        await instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            const accessToken = accessTokenResponse.accessToken
            config.headers.Authorization = `Bearer ${accessToken}`
          })
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          await instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              const accessToken = accessTokenResponse.accessToken
              config.headers.Authorization = `Bearer ${accessToken}`
            })
        } else {
          throw error
        }
      }
      return config
    }, function (error) {
      // Do something with request error
      return Promise.reject(error)
    })
}

export { addErrorHandler, addTokenHandler, setMsalContext }

import '../../../asset/css/pilotManagement.css'

import { ActionButton, DetailsList, DetailsListLayoutMode, Dropdown, Label, PrimaryButton, SelectionMode, Separator, Shimmer, Stack, Text, TextField } from '@fluentui/react'
import React, { useRef, useState } from 'react'
import { addUsersForPilotFeature, checkUserHasPilotFeature, getPilotFeatureUsers, removeUsersForPilotFeature } from '../../../service/pilot.service'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import Papa from 'papaparse'
import PropTypes from 'prop-types'

function DomainManageSection (props) {
  const [domainInput, setDomainInput] = useState('')
  const [hasSearched, setHasSearched] = useState(false)
  const [batchActionSelected, setBatchActionSelected] = useState(null)
  const [batchFile, setBatchFile] = useState(null)
  const [csvErrors, setCsvErrors] = useState([])
  const [csvRowCount, setCsvRowCount] = useState(0)
  const [parsedDomains, setParsedDomains] = useState([])
  const [isPilotEnabled, setIsPilotEnabled] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const fileInputRef = useRef(null)

  const queryClient = useQueryClient()

  const fetchCheckUserHasPilotFeature = async () => {
    const response = await checkUserHasPilotFeature(props.selectedId, domainInput.trim())
    setIsPilotEnabled(response)
  }

  const { data: pilotFeatureDomains, isLoading: pilotFeatureDomainsLoading, isError: pilotFeatureDomainsError } = useQuery({
    queryKey: ['pilotFeatureUsers', props.selectedId],
    queryFn: () => getPilotFeatureUsers(props.selectedId)
  })

  const addUsersMutation = useMutation({
    mutationFn: (addUsersRequest) => addUsersForPilotFeature(props.selectedId, addUsersRequest),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['pilotFeatureUsers', props.selectedId])
      if (!domainInput.trim()) return
      fetchCheckUserHasPilotFeature().then(() => setHasSearched(true))
    }
  })

  const removeUsersMutation = useMutation({
    mutationFn: (removeUsersRequest) => removeUsersForPilotFeature(props.selectedId, removeUsersRequest),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['pilotFeatureUsers', props.selectedId])
      if (!domainInput.trim()) return
      fetchCheckUserHasPilotFeature().then(() => setHasSearched(true))
    }
  })

  const handleSearch = async () => {
    await fetchCheckUserHasPilotFeature()
    setHasSearched(true)
  }

  const handleDomainInputChanged = (e, value) => {
    setHasSearched(false)
    setIsPilotEnabled(false)
    setDomainInput(value)
  }

  const handleSingleAddAction = () => {
    const addUsersRequest = {
      domains: [domainInput]
    }
    addUsersMutation.mutate(addUsersRequest)
  }

  const handleSingleRemoveAction = () => {
    const removeUsersRequest = {
      domains: [domainInput]
    }
    removeUsersMutation.mutate(removeUsersRequest)
  }

  const handleBatchFileChange = (event) => {
    const file = event.target.files[0]
    setBatchFile(file)

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const errors = []
          const domains = results.data.map(row => row.domain).filter(domain => domain)
          const rowCount = results.data.length
          if (domains.length === 0) {
            errors.push('No domains found in the file.')
          }

          setCsvErrors(errors)
          setCsvRowCount(rowCount)
          setParsedDomains(domains)
        },
        error: (error) => {
          setCsvErrors([`Error reading file: ${error.message}`])
        }
      })
    }
  }

  const handleBatchAction = () => {
    if (batchActionSelected === 'batchAdd') {
      const addUsersRequest = {
        domains: parsedDomains
      }
      addUsersMutation.mutate(addUsersRequest)
    } else if (batchActionSelected === 'batchRemove') {
      const removeUsersRequest = {
        domains: parsedDomains
      }
      removeUsersMutation.mutate(removeUsersRequest)
    }
    // reset the file input
    setBatchFile(null)
    setCsvRowCount(0)
    setParsedDomains([])
    fileInputRef.current.value = null
  }

  const renderSingleDomainManageSection = () => {
    const columns = [
      { key: 'domain', name: 'Domain', fieldName: 'domain', minWidth: 200, maxWidth: 200 },
      { key: 'result', name: 'Result', fieldName: 'result', minWidth: 200, maxWidth: 200 },
      { key: 'action', name: 'Action', fieldName: 'action', minWidth: 100, maxWidth: 120 }
    ]

    const searchResult = hasSearched
      ? [
          {
            domain: <div className='feature-list-cell'>{domainInput}</div>,
            result: isPilotEnabled
              ? <div className='feature-list-cell'><div className='is-feature-enabled'>Has this feature</div></div>
              : <div className='feature-list-cell'><div className='is-feature-disabled'>Does not have this feature</div></div>,
            action: isPilotEnabled
              ? (
              <div className='feature-list-cell'>
                <PrimaryButton
                  text='Remove'
                  onClick={handleSingleRemoveAction}
                  style={{ backgroundColor: '#CE4747', border: '0px' }}
                />
              </div>
                )
              : (
              <div className='feature-list-cell'>
                <PrimaryButton text='Add' onClick={handleSingleAddAction} />
              </div>
                )
          }
        ]
      : [{ domain: '-', result: '-', action: '-' }]

    return (
      <div className={addUsersMutation.isPending || removeUsersMutation.isPending ? 'div-disabled' : ''}>
        <Stack>
          <h3>1. Single Domain Management</h3>
          <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 8 }}>
            <TextField
              label="Single Domain"
              value={domainInput}
              onChange={handleDomainInputChanged}
              style={{ width: '440px' }}
            />
            <PrimaryButton
              text="Search"
              onClick={handleSearch}
              disabled={!domainInput}
            />
          </Stack>
          <DetailsList
            items={searchResult}
            columns={columns}
            setKey='none'
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            isHeaderVisible={true}
            compact={true}
          />
        </Stack>
      </div>
    )
  }

  const renderBatchDomainManageSection = () => {
    const batchActions = [
      { key: 'batchAdd', text: 'Batch Add' },
      { key: 'batchRemove', text: 'Batch Remove' }
    ]

    return (
      <div className={addUsersMutation.isPending || removeUsersMutation.isPending ? 'div-disabled' : ''}>
        <Stack>
          <h3>2. Batch Domain Management</h3>
          <Stack tokens={{ childrenGap: 24 }}>
            <Dropdown
              label='Action'
              options={batchActions}
              style={{ width: '320px' }}
              placeholder='Select an option'
              selectedKey={batchActionSelected}
              onChange={(e, item) => setBatchActionSelected(item.key)}
            />
            <Stack tokens={{ childrenGap: 12 }}>
              <Stack verticalAlign='end' tokens={{ childrenGap: 4 }}>
                <Stack horizontal tokens={{ childrenGap: 24 }} verticalAlign='center'>
                  <Label>Upload a CSV file</Label>
                </Stack>
                <input
                  type='file'
                  accept='.csv'
                  onChange={handleBatchFileChange}
                  ref={fileInputRef}
                />
              </Stack>
              <Text variant="medium">CSV file format: domain</Text>
              {csvErrors.length > 0 && (
                <Stack>
                  <Text variant="large" styles={{ root: { color: 'red' } }}>Errors:</Text>
                  <ul>
                    {csvErrors.map((error, index) => (
                      <li key={index} style={{ fontSize: '14px' }}>{error}</li>
                    ))}
                  </ul>
                </Stack>
              )}
              {csvRowCount > 0 && !csvErrors.length && (
                <Text styles={{ root: { color: 'darkGreen' } }}>Row count: {csvRowCount}</Text>
              )}
            </Stack>
            <PrimaryButton
              style={{ width: '320px' }}
              text='Execute'
              onClick={handleBatchAction}
              disabled={!batchActionSelected || !batchFile || csvErrors.length > 0}
            />
          </Stack>
        </Stack>
      </div>
    )
  }

  const renderPagination = () => {
    const totalPages = pilotFeatureDomains?.domains?.length ? Math.ceil(pilotFeatureDomains.domains.length / itemsPerPage) : 1

    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <ActionButton
          iconProps={{ iconName: 'ChevronLeft' }}
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        />
        <Text variant="mediumPlus"> {currentPage} / {totalPages}</Text>
        <ActionButton
          iconProps={{ iconName: 'ChevronRight' }}
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        />
      </Stack>
    )
  }

  const renderDomainList = () => {
    const shimmeredItems = Array.from({ length: itemsPerPage }).map((_, index) => ({ domain: <Shimmer key={index} /> }))
    if (pilotFeatureDomainsLoading || pilotFeatureDomainsError) {
      return (
        <Stack>
          <h3>Enabled Domain List</h3>
          <DetailsList
            items={shimmeredItems}
            columns={[{ key: 'domain', name: 'Domain', fieldName: 'domain', minWidth: 320, maxWidth: 320 }]}
            setKey='none'
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            isHeaderVisible={true}
            compact={true}
          />
        </Stack>
      )
    }

    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const currentItems = pilotFeatureDomains?.domains.slice(startIndex, endIndex) || []

    return (
      <Stack>
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 32 }}>
        <h3>Enabled Domain List</h3>
        <Text style={{ color: 'gray' }}>Domain Count: {pilotFeatureDomains?.domains.length || 0}</Text>
        </Stack>
        <Stack horizontalAlign='center' tokens={{ childrenGap: 8 }}>
          <div style={{ height: '400px' }}>
            <DetailsList
              items={currentItems.map((domain, index) => ({ domain: <div key={index} className='feature-list-cell'>{domain}</div> }))}
              columns={[
                { key: 'domain', name: 'Domain', fieldName: 'domain', minWidth: 320, maxWidth: 320 }
              ]}
              setKey='none'
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              isHeaderVisible={true}
              compact={true}
            />
          </div>
          {renderPagination()}
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack>
      <h2>Domains Management</h2>
      <Stack horizontal tokens={{ childrenGap: 48 }}>
        <Stack tokens={{ childrenGap: 24 }}>
          {renderSingleDomainManageSection()}
          {renderBatchDomainManageSection()}
        </Stack>
        <Separator vertical />
        {renderDomainList()}
      </Stack>
    </Stack>
  )
}

DomainManageSection.propTypes = {
  selectedId: PropTypes.number
}

export default DomainManageSection

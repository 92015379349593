import React from 'react'
import path from '../../../model/path'
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu'
import { useNavigate } from 'react-router'

function MenuInformation () {
  const linkRef = React.useRef(null)
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  function onOpen () {
    setOpen(true)
  }
  function onClose () {
    setOpen(false)
  }
  const menuItems = [
    {
      key: 'Shopify',
      text: 'Shopify Sales Channel',
      onClick: () => navigate(path.shopify)
    },
    {
      key: 'Google',
      text: 'Google Merchant Center Import',
      onClick: () => navigate(path.google)
    }
  ]

  return (
    <div className='header-item clickable'>
      <div onClick={onOpen} ref={linkRef}>Information</div>
      <ContextualMenu
        items={menuItems}
        hidden={!open}
        target={linkRef}
        onItemClick={onOpen}
        onDismiss={onClose}
      />
    </div>
  )
}
export default MenuInformation

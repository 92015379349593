/* eslint-disable camelcase */
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
function GroupId_MerchantIds (props) {
  const columns = [
    {
      key: 'GroupId',
      fieldName: 'GroupId',
      name: 'GroupId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'MerchantIds',
      fieldName: 'MerchantIds',
      name: 'MerchantIds',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    }
  ]
  const data = props.data.map(item => {
    return {
      GroupId: item.GroupId,
      MerchantIds: item.MerchantIds.join(',')
    }
  })
  return (
    <DetailsList
      items={data}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
    />
  )
}
GroupId_MerchantIds.propTypes = {
  data: PropTypes.array.isRequired
}
export default GroupId_MerchantIds

import React from 'react'
import NoAccessModal from './noAccessModal'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import accessRight from '../model/accessRight'
import PropTypes from 'prop-types'
import UnauthorizationModal from './unauthorizedModal'

function Unauthorized (props) {
  return (
    <div className='window-center'>
      <h1>Reuqire Authorization. Please login first.</h1>
      <UnauthorizationModal/>
    </div>

  )
}
Unauthorized.propTypes = {
  element: PropTypes.any.isRequired,
  requiredAccessRight: PropTypes.number.isRequired
}

function Authorized (props) {
  const { accounts } = useMsal()
  const roles = (accounts[0] && accounts[0].idTokenClaims && accounts[0].idTokenClaims.roles) ?? []
  let userAccessRight = accessRight.Logged
  roles.forEach(role => {
    switch (role) {
      case 'PicAdmin.Read':
      case 'Read':
        userAccessRight = userAccessRight | accessRight.Read
        break
      case 'PicAdmin.Write':
      case 'Write':
        userAccessRight = userAccessRight | accessRight.Read | accessRight.Write
        break
    }
  })
  if (userAccessRight & props.requiredAccessRight === 0) {
    return (
      <div className='window-center'>
        <h1>Do not have access. Please contact PIC team.</h1>
        <NoAccessModal/>
      </div>
    )
  }
  return props.element
}
Authorized.propTypes = {
  element: PropTypes.any.isRequired,
  requiredAccessRight: PropTypes.number.isRequired
}

function AccessController (props) {
  return (
    <>
      <AuthenticatedTemplate>
        <Authorized element={props.element} requiredAccessRight={props.requiredAccessRight}/>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Unauthorized element={props.element} requiredAccessRight={props.requiredAccessRight}/>
      </UnauthenticatedTemplate>
    </>
  )
}
AccessController.propTypes = {
  element: PropTypes.any.isRequired,
  requiredAccessRight: PropTypes.number.isRequired
}
export default AccessController

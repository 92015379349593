import { createSlice } from '@reduxjs/toolkit'

export const generalSlice = createSlice({
  name: 'general',
  initialState: {},

  reducers: {
  }
})
export default generalSlice.reducer

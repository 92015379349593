import React, { useState } from 'react'
import { AccountInfoList } from './accountInfoList'
import { MmcInfoList } from './mmcInfoList'
import { MmcCatalogsList } from './mmcCatalogsList'
import { ProductsStatusList } from './productsStatusList'
import { CampaignStatusList } from './campaignStatusList'
import { ProductIssuesList } from './productIssuesList'
import { SkeletionPage } from './skeletonPage'
import { getAccountStatus, getMmcStoreInfo, GetOffersStatus, GetCampaignInfo, GetProductIssues, getFeedDownloadInfo, getFeedImportInfo } from '../../service/shopify.service'
import { Image, PrimaryButton, SearchBox } from '@fluentui/react'
import msSearchPage from '../../asset/img/ms-searchpage.png'

const ShopifyInfo = (props) => {
  const [shopifyDomain, setShopifyDomain] = useState('')
  const [loading, setLoading] = useState(true)
  const [accountData, setAccountData] = useState()
  const [mmcData, setMmcData] = useState()
  const [feedDownloadInfoData, setFeedDownloadInfoData] = useState()
  const [feedImportInfoData, setFeedImportInfoData] = useState()
  const [offerData, setOfferData] = useState()
  const [campaignData, setCampaignData] = useState()
  const [productIssuesData, setProductIssuesData] = useState()

  const [errorMessage, setErrorMessage] = useState('')
  const [accountError, setAccountError] = useState('')
  const [mmcError, setMmcError] = useState('')
  const [feedDownloadInfoError, setFeedDownloadInfoError] = useState('')
  const [feedImportInfoError, setFeedImportInfoError] = useState('')
  const [offerError, setOfferError] = useState('')
  const [campaignError, setCampaignError] = useState('')
  const [productIssueError, setProductIssueError] = useState('')

  const [displayMsSearchPage, setDisplayMsSearchPage] = useState(true)

  const fetchShopifyInfo = async (shopifyDomain) => {
    if (shopifyDomain === null || shopifyDomain === '') {
      return
    }
    console.log(shopifyDomain)
    setLoading(true)
    await Promise.all([
      fetchAccountStatus(shopifyDomain),
      fetchMmcStoreInfo(shopifyDomain),
      fetchOffersStatus(shopifyDomain),
      fetchCampaignInfo(shopifyDomain),
      fetchProductIssues(shopifyDomain),
      fetchFeedDownloadInfo(shopifyDomain),
      fetchFeedImportInfo(shopifyDomain)
    ]).catch(error => {
      setErrorMessage((error.response && error.response.data && error.response.data.ExceptionMessage) ?? 'Something wrong happened.')
      throw error
    }).finally(() => {
      setLoading(false)
    })
  }

  const fetchAccountStatus = async (shopifyDomain) => {
    try {
      setAccountError('')
      setAccountData(undefined)
      const response = await getAccountStatus(shopifyDomain)
      setAccountData(response)
    } catch (error) {
      setAccountError(error?.response?.data?.ExceptionMessage ?? 'Failed to load Account Status')
    }
  }

  const fetchMmcStoreInfo = async (shopifyDomain) => {
    try {
      setMmcError('')
      setMmcData(undefined)
      const response = await getMmcStoreInfo(shopifyDomain)
      setMmcData(response)
    } catch (error) {
      setMmcError(error?.response?.data?.ExceptionMessage ?? 'Failed to load Mmc Store Information')
    }
  }

  const fetchOffersStatus = async (shopifyDomain) => {
    try {
      setOfferError('')
      setOfferData(undefined)
      const response = await GetOffersStatus(shopifyDomain)
      setOfferData(response)
    } catch (error) {
      setOfferError(error?.response?.data?.ExceptionMessage ?? 'Failed to load Offer Status')
    }
  }

  const fetchCampaignInfo = async (shopifyDomain) => {
    try {
      setCampaignError('')
      setCampaignData(undefined)
      const response = await GetCampaignInfo(shopifyDomain)
      setCampaignData(response)
    } catch (error) {
      setCampaignError(error?.response?.data?.ExceptionMessage ?? 'Failed to load Campaign Information')
    }
  }

  const fetchProductIssues = async (shopifyDomain) => {
    try {
      setProductIssueError('')
      setProductIssuesData(undefined)
      const response = await GetProductIssues(shopifyDomain)
      setProductIssuesData(response)
    } catch (error) {
      setProductIssueError(error?.response?.data?.ExceptionMessage ?? 'Failed to load Product Issue')
    }
  }

  const fetchFeedDownloadInfo = async (shopifyDomain) => {
    try {
      setFeedDownloadInfoError('')
      setFeedDownloadInfoData(undefined)
      const response = await getFeedDownloadInfo(shopifyDomain)
      setFeedDownloadInfoData(response)
    } catch (error) {
      setFeedDownloadInfoError(error?.response?.data?.ExceptionMessage ?? 'Failed to load Feed Download Info')
    }
  }

  const fetchFeedImportInfo = async (shopifyDomain) => {
    try {
      setFeedImportInfoError('')
      setFeedImportInfoData(undefined)
      const response = await getFeedImportInfo(shopifyDomain)
      setFeedImportInfoData(response)
    } catch (error) {
      setFeedImportInfoError(error?.response?.data?.ExceptionMessage ?? 'Failed to load Feed Import Info')
    }
  }

  return (
    <div className='main-box' >
      { displayMsSearchPage &&
        <Image
          src={ msSearchPage }
          alt="Example with no image fit value and height or width is specified."
          style = {{ margin: '120px auto 8px' }}
        />
      }
      <div style = {{ margin: '0 auto', width: '70%' }}>
        <div style = {{ display: 'flex' }}>
          <SearchBox placeholder="Please Enter Shopify Store Domain"
            styles = {{ root: { width: '100%' } }}
            onChange = {(_, newValue) => {
              setShopifyDomain(newValue)
            }}
            onSearch = { () => {
              setErrorMessage('')
              fetchShopifyInfo(shopifyDomain)
              setDisplayMsSearchPage(!shopifyDomain || shopifyDomain === '')
            }}
          />
          <PrimaryButton text="Search"
            styles = {{ root: { marginLeft: '16px' } }}
            onClick={ () => {
              setErrorMessage('')
              fetchShopifyInfo(shopifyDomain)
              setDisplayMsSearchPage(!shopifyDomain || shopifyDomain === '')
            }} />
        </div>
        <div className='error'>{errorMessage}</div>
      </div>
      {
        !displayMsSearchPage &&
        <>
          {loading
            ? <SkeletionPage />
            : <><AccountInfoList accountInfo = { accountData } error={accountError}/>
              <MmcInfoList mmcInfo = { mmcData } error={mmcError}/>
              <MmcCatalogsList mmcInfo = { mmcData } error={mmcError || feedDownloadInfoError || feedImportInfoError} feedDownloadInfo={feedDownloadInfoData} feedImportInfo={feedImportInfoData} updateFeedImportInfo={() => fetchFeedImportInfo(shopifyDomain)}/>
              <ProductsStatusList productStatus = { offerData } error={offerError}/>
              <ProductIssuesList productIssues = { productIssuesData } error={productIssueError}/>
              <CampaignStatusList campaignStatus = { campaignData } error={campaignError}/> </>
          }
        </>
      }
    </div>

  )
}
export default ShopifyInfo

/* eslint-disable camelcase */
import { Stack } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
import LogList from './LogList'
import OldGroupId_MerchantIds_OfferCount from './OldGroupId_MerchantIds_OfferCount'
import NewGroupId_MerchantIds_OfferCount from './NewGroupId_MerchantIds_OfferCount'
import CreateCatalogLog from './CreateCatalogLog'
import DeletedGroupId from './DeletedGroupId'
import GroupId_MerchantIdCount_OfferCount from './GroupId_MerchantIdCount_OfferCount'
import OldCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount from './OldCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount'
import NewCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount from './NewCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount'
import DeletedCatalogId_Market_GroupId_CatalogMerchantIds from './DeletedCatalogId_Market_GroupId_CatalogMerchantIds'
import NewCatalogId_NewCatalogName_Market_CatalogMerchantIds_GroupId from './NewCatalogId_NewCatalogName_Market_CatalogMerchantIds_GroupId'
import UpdateOldGroupSQL from './UpdateOldGroupSQL'
import AddNewGroupSQL from './AddNewGroupSQL'
import MerchantId_Market_OldCatalogId_NewCatalogId_Status from './MerchantId_Market_OldCatalogId_NewCatalogId_Status'
import Status_Count from './Status_Count'
import CatalogId_CatalogName from './CatalogId_CatalogName'
import CatalogId_Market_GroupId_CatalogMerchantIds from './CatalogId_Market_GroupId_CatalogMerchantIds'
import GroupId_MerchantIds from './GroupId_MerchantIds'
import ProviderId_TokenInfoId from './ProviderId_TokenInfoId'

function MultiStoreImportInfo (props) {
  const result = props.result
  const multiStoreImportData = props.multiStoreImportData
  return (
    <div>
      <Stack tokens={{ childrenGap: 4 }}>
        <h3>RetrieveMultiStoreImportData</h3>
        <CatalogId_CatalogName data={(multiStoreImportData && multiStoreImportData.CatalogId_CatalogName) || []}/>
        <CatalogId_Market_GroupId_CatalogMerchantIds data={(multiStoreImportData && multiStoreImportData.CatalogId_Market_GroupId_CatalogMerchantIds) || []}/>
        <GroupId_MerchantIds data={(multiStoreImportData && multiStoreImportData.GroupId_MerchantIds) || []}/>
        <ProviderId_TokenInfoId data={(multiStoreImportData && multiStoreImportData.ProviderId_TokenInfoId) || []}/>
        <hr/>

        <h3>RefreshMerchantGroups</h3>
        <OldGroupId_MerchantIds_OfferCount data={(result && result.OldGroupId_MerchantIds_OfferCount) || []}/>
        <NewGroupId_MerchantIds_OfferCount data={(result && result.NewGroupId_MerchantIds_OfferCount) || []}/>
        <DeletedGroupId data={(result && result.DeletedGroupId) || []}/>
        <GroupId_MerchantIdCount_OfferCount data={(result && result.GroupId_MerchantIdCount_OfferCount) || []}/>
        <hr/>

        <h3>RefreshMerchantGroupCatalogs</h3>
        <OldCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount data={(result && result.OldCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount) || []}/>
        <NewCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount data={(result && result.NewCatalogId_Market_GroupId_CatalogMerchantIds_OfferCount) || []}/>
        <DeletedCatalogId_Market_GroupId_CatalogMerchantIds data={(result && result.DeletedCatalogId_Market_GroupId_CatalogMerchantIds) || []}/>
        <hr/>

        <h3>CreateGImportCatalogs</h3>
        <LogList data={(result && result.LogList) || []}/>
        <NewCatalogId_NewCatalogName_Market_CatalogMerchantIds_GroupId data={(result && result.NewCatalogId_NewCatalogName_Market_CatalogMerchantIds_GroupId) || []}/>
        <CreateCatalogLog data={(result && result.CreateCatalogLog) || []}/>
        <hr/>

        <h3>GenerateSQLUpdateScript</h3>
        <UpdateOldGroupSQL data={(result && result.UpdateOldGroupSQL) || []}/>
        <AddNewGroupSQL data={(result && result.AddNewGroupSQL) || []}/>
        <MerchantId_Market_OldCatalogId_NewCatalogId_Status data={(result && result.MerchantId_Market_OldCatalogId_NewCatalogId_Status) || []}/>
        <Status_Count data={(result && result.Status_Count) || []}/>
      </Stack>
    </div>
  )
}
MultiStoreImportInfo.propTypes = {
  multiStoreImportData: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired
}

export default MultiStoreImportInfo

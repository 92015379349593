/* eslint-disable camelcase */
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'

function DeletedCatalogId_Market_GroupId_CatalogMerchantIds (props) {
  const columns = [
    {
      key: 'DeletedCatalogId',
      fieldName: 'DeletedCatalogId',
      name: 'DeletedCatalogId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'Market',
      fieldName: 'Market',
      name: 'Market',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'GroupId',
      fieldName: 'GroupId',
      name: 'GroupId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'CatalogMerchantIds',
      fieldName: 'CatalogMerchantIds',
      name: 'CatalogMerchantIds',
      isResizable: true,
      minWidth: 120
    }
  ]
  return (
    <DetailsList
      items={props.data}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
    />
  )
}
DeletedCatalogId_Market_GroupId_CatalogMerchantIds.propTypes = {
  data: PropTypes.array.isRequired
}
export default DeletedCatalogId_Market_GroupId_CatalogMerchantIds

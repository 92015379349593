import * as React from 'react'
import PropTypes from 'prop-types'
import { PrimaryButton } from '@fluentui/react'
import googleService from '../service/google.service'
const SyncButton = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false)
  const onSync = async () => {
    try {
      setLoading(true)
      await googleService.createFeedSyncTask(props.catalogId)
      await props.updateFeedImportInfo()
    } finally {
      setLoading(false)
    }
  }
  return (
    <PrimaryButton onClick={onSync} disabled={loading || !props.catalogId || props.disabled} >
      Sync Now
    </PrimaryButton>
  )
}

SyncButton.propTypes = {
  providerId: PropTypes.number,
  catalogId: PropTypes.number,
  disabled: PropTypes.bool,
  updateFeedImportInfo: PropTypes.func
}
export default SyncButton

import React from 'react'
import PropTypes from 'prop-types'

export const ProductsStatusCard = (props) => {
  const cardStyle = {
    backgroundColor: props.backgroundColor,
    width: '132px',
    height: '132px',
    textAlign: 'center',
    marginRight: '16px'
  }
  const numberStyle = {
    fontFamily: 'Segoe UI',
    fontWeight: 400,
    fontSize: '24px',
    color: '#FFFFFF',
    marginTop: '32px'
  }
  const textStyle = {
    fontFamily: 'Segoe UI',
    fontWeight: 400,
    fontSize: '14px',
    color: '#FFFFFF',
    marginTop: '8px'
  }
  return (
    <div style={cardStyle}>
      <div style={numberStyle}>
        { props.count }
      </div>
      <div style={textStyle}>
        { props.status }
      </div>
    </div>
  )
}

ProductsStatusCard.propTypes = {
  backgroundColor: PropTypes.any,
  count: PropTypes.any,
  status: PropTypes.any
}

import * as React from 'react'
import { DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList'
import PropTypes from 'prop-types'
import googleService from '../../service/google.service'
import SyncButton from '../../component/syncButton'
import util from '../../util'
const columns = [
  {
    key: 'CatalogId',
    fieldName: 'CatalogId',
    name: 'Catalog Id',
    isResizable: true,
    minWidth: 80,
    maxWidth: 80
  },
  {
    key: 'GoogleMerchantId',
    fieldName: 'GoogleMerchantId',
    name: 'Google Merchant Id',
    isResizable: true,
    minWidth: 80
  },
  {
    key: 'TargetCountry',
    fieldName: 'TargetCountry',
    name: 'Target Country',
    isResizable: true,
    minWidth: 120,
    maxWidth: 160
  },
  {
    key: 'Enabled',
    fieldName: 'Enabled',
    name: 'Enabled',
    isResizable: true,
    minWidth: 80,
    maxWidth: 160
  },
  {
    key: 'ModifiedOn',
    fieldName: 'ModifiedOn',
    name: 'Modified On',
    isResizable: true,
    minWidth: 160,
    maxWidth: 160
  },
  {
    key: 'LastActivetyTime',
    fieldName: 'LastActivetyTime',
    name: 'Last Activety Time',
    isResizable: true,
    minWidth: 160,
    maxWidth: 160
  },
  {
    key: 'StatusName',
    fieldName: 'StatusName',
    name: 'Status',
    isResizable: true,
    minWidth: 120,
    maxWidth: 240
  },
  {
    key: 'Action',
    fieldName: 'Action',
    name: 'Action',
    isResizable: true,
    minWidth: 120,
    maxWidth: 160
  }
]
const CatalogInfo = (props) => {
  const [feedImportInfo, setFeedImportInfo] = React.useState({})

  const updateFeedImportInfo = async () => {
    const catalogIds = props.feedDownloadInfo.map(item => item.CatalogId)
    catalogIds.map(async catalogId => {
      const feedImportInfoOfCatalog = await googleService.getFeedImportInfo(props.providerId, catalogId)
      feedImportInfo[catalogId] = feedImportInfoOfCatalog
      setFeedImportInfo({ ...feedImportInfo })
    })
  }

  React.useEffect(() => {
    updateFeedImportInfo()
  }, [props.feedDownloadInfo])

  const data = props.feedDownloadInfo.map(item => {
    const _item = { ...item }
    const catalogId = item.CatalogId
    if (feedImportInfo[catalogId]) {
      const feedImportInfoOfCatalog = feedImportInfo[catalogId]
      const statusCode = feedImportInfoOfCatalog.Status
      const errorCode = feedImportInfoOfCatalog.errorCode
      const statusName = util.getFeedImportStatusName(statusCode)
      const dateTimeString = feedImportInfoOfCatalog.DateTime.endsWith('Z') ? feedImportInfoOfCatalog.DateTime : feedImportInfoOfCatalog.DateTime + 'Z'
      const dateTime = new Date(dateTimeString)
      _item.StatusName = statusCode === 4 ? `${statusName}:${errorCode}` : statusName
      _item.LastActivetyTime = dateTime.toLocaleString()
    }
    const dateTimeString = _item.ModifiedOn.endsWith('Z') ? _item.ModifiedOn : _item.ModifiedOn + 'Z'
    const dateTime = new Date(dateTimeString)
    _item.ModifiedOn = dateTime.toLocaleString()
    _item.Action = <SyncButton catalogId={item.CatalogId} disabled={item.Enabled === false || item.GoogleMerchantId.includes(',')} providerId={props.providerId} updateFeedImportInfo={updateFeedImportInfo}/>
    return _item
  })

  return (
    <>
      <DetailsList
        items={data}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </>
  )
}
CatalogInfo.propTypes = {
  tokenInfo: PropTypes.object.isRequired,
  feedDownloadInfo: PropTypes.array.isRequired,
  providerId: PropTypes.string.isRequired
}
export default CatalogInfo

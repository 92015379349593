import * as React from 'react'
import { DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList'
import PropTypes from 'prop-types'
import googleService from '../../service/google.service'

const columns = [
  {
    key: 'MerchantAccount',
    fieldName: 'MerchantAccount',
    name: 'Merchant Account',
    isResizable: true,
    minWidth: 120,
    maxWidth: 240
  },
  {
    key: 'TokenInfoId',
    fieldName: 'TokenInfoId',
    name: 'Token Info Id',
    isResizable: true,
    minWidth: 120,
    maxWidth: 240
  },
  {
    key: 'ModifyTime',
    fieldName: 'ModifyTime',
    name: 'Modified On',
    isResizable: true,
    minWidth: 160,
    maxWidth: 240
  },
  {
    key: 'TokenStatus',
    fieldName: 'TokenStatus',
    name: 'Token Status',
    isResizable: true,
    minWidth: 120,
    maxWidth: 240
  }
]

const MerchantInfo = (props) => {
  const [tokenValidity, setTokenValidity] = React.useState(null)

  const loadTokenValidity = async () => {
    const merchantIds = props.feedDownloadInfo.map(item => item.GoogleMerchantId)
    const validateTasks = merchantIds.map(merchantId => {
      if (merchantId.includes(',')) { return null }
      return googleService
        .checkToken(props.providerId, merchantId)
        .catch(error => {
          console.error('Validate Token Failed:')
          console.error(error)
          return false
        })
        .then(val => {
          if (val) { setTokenValidity(true) }
          return val
        })
    })

    Promise.all(validateTasks).then(values => {
      if (values.includes(true)) {
        setTokenValidity(true)
      } else if (values.includes(false)) {
        setTokenValidity(false)
      } else {
        setTokenValidity(null)
      }
    })
  }

  React.useEffect(() => {
    if (props.feedDownloadInfo.length > 0) {
      loadTokenValidity()
    }
  }, [props.tokenInfo])

  const modifiedTimeLocale = new Date(props.tokenInfo.ModifiedTimeUtc)
  const data = [{
    GoogleMerchantId: props.feedDownloadInfo.length > 0 ? props.feedDownloadInfo[0].GoogleMerchantId : '-',
    MerchantAccount: props.tokenInfo.MerchantAccount,
    TokenInfoId: props.tokenInfo.Id,
    IsTokenRevoked: props.tokenInfo.Revoked,
    ModifyTime: `${modifiedTimeLocale.toLocaleString()}`,
    TokenStatus: typeof (tokenValidity) !== 'boolean' ? '-' : tokenValidity ? 'Valid' : 'Invalid'
  }]

  return (
    <>
      <DetailsList
        items={data}
        compact={true}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </>
  )
}
MerchantInfo.propTypes = {
  tokenInfo: PropTypes.object.isRequired,
  feedDownloadInfo: PropTypes.array.isRequired,
  providerId: PropTypes.string.isRequired
}
export default MerchantInfo

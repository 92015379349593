import React, { useEffect } from 'react'
import databaseIcon from '../../asset/img/database-icon.svg'
import '../../asset/css/query.css'
import { PrimaryButton, Stack } from '@fluentui/react'
import PersonalQueryList from './components/personalQueryList'
import { useDispatch, useSelector } from 'react-redux'
import QueryDetail from './components/queryDetail'
import { updateActiveTab, updateFavouriteQueryIds } from '../../store/feature/querySlice'
import PublicQueryList from './components/publicQueryList'
import tab from './model/tab'
import queryService from '../../service/query.service'
import { useMsal } from '@azure/msal-react'

function Queries () {
  const dispatch = useDispatch()
  const { accounts } = useMsal()
  const userName = accounts[0] && accounts[0].username
  const activeTab = useSelector(state => state.query.activeTab)
  const selectedQuery = useSelector(state => state.query.selectedQuery)

  async function loadFavouriteQueryIds () {
    const favouriteQueryIds = await queryService.getFavouriteQueryIds(userName)
    dispatch(updateFavouriteQueryIds(favouriteQueryIds))
  }

  function onPersonalQuery () {
    dispatch(updateActiveTab(tab.personalQueries))
  }

  function onPublicQuery () {
    dispatch(updateActiveTab(tab.publicQueries))
  }

  function onNewQuery () {
    dispatch(updateActiveTab(tab.newQuery))
  }

  function onQueryDetail () {
    dispatch(updateActiveTab(tab.queryDetail))
  }
  useEffect(() => {
    loadFavouriteQueryIds()
  }, [])

  return (
    <div className='main-box'>
      <div className='flex-row'>
        <h2>Database Queries</h2>
        <img src={databaseIcon} alt='' height='32px' style={{ marginLeft: '8px' }}/>
      </div>
      <hr/>
      <Stack tokens={{ childrenGap: 8 }}>
        <Stack horizontal tokens={{ childrenGap: 8 }}>
          <PrimaryButton text="Personal Queries" allowDisabledFocus checked={activeTab === tab.personalQueries} onClick={() => onPersonalQuery()}/>
          <PrimaryButton text="Shared Queries" allowDisabledFocus checked={activeTab === tab.publicQueries} onClick={() => onPublicQuery()}/>
          <PrimaryButton text="New Query" allowDisabledFocus checked={activeTab === tab.newQuery} onClick={() => onNewQuery()}/>
          { selectedQuery && <PrimaryButton text={selectedQuery.Title} allowDisabledFocus checked={activeTab === tab.queryDetail} onClick={() => onQueryDetail()}/>}
        </Stack>
        {activeTab === tab.personalQueries && <PersonalQueryList/>}
        {activeTab === tab.publicQueries && <PublicQueryList/>}
        {activeTab === tab.newQuery && <QueryDetail />}
        {activeTab === tab.queryDetail && selectedQuery && <QueryDetail queryInfo={selectedQuery}/>}
      </Stack>
    </div>
  )
}
export default Queries

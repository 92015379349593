import React, { useEffect, useState } from 'react'
import downloaderMonitorService from '../../service/downloaderMonitor.service'
import { DetailsList, DetailsListLayoutMode, PrimaryButton, SelectionMode, Stack, TextField } from '@fluentui/react'
import util from '../../util'
import productsService from '../../service/products.service'
import SyncButton from '../../component/syncButton'
import { useMsal } from '@azure/msal-react'

const columns = [
  {
    key: 'TenantId',
    fieldName: 'TenantId',
    name: 'TenantId',
    minWidth: 80,
    maxWidth: 80
  },
  {
    key: 'CustomerId',
    fieldName: 'CustomerId',
    name: 'CustomerId',
    minWidth: 80,
    maxWidth: 80
  },
  {
    key: 'ProviderId',
    fieldName: 'ProviderId',
    name: 'ProviderId',
    minWidth: 80,
    maxWidth: 80
  },
  {
    key: 'CatalogId',
    fieldName: 'CatalogId',
    name: 'CatalogId',
    minWidth: 80,
    maxWidth: 80
  },
  {
    key: 'MerchantId',
    fieldName: 'MerchantId',
    name: 'MerchantId',
    minWidth: 80,
    maxWidth: 80
  },
  {
    key: 'ContactEmail',
    fieldName: 'ContactEmail',
    name: 'ContactEmail',
    isResizable: true,
    minWidth: 160
  },
  {
    key: 'SupportEmail',
    fieldName: 'SupportEmail',
    name: 'SupportEmail',
    isResizable: true,
    minWidth: 160
  },
  {
    key: 'LastActivetyTime',
    fieldName: 'LastActivetyTime',
    name: 'LastActivetyTime',
    isResizable: true,
    minWidth: 160,
    maxWidth: 160
  },
  {
    key: 'StatusName',
    fieldName: 'StatusName',
    name: 'DownloadStatus',
    isResizable: true,
    minWidth: 80,
    maxWidth: 80
  },
  {
    key: 'ErrorCode',
    fieldName: 'ErrorCode',
    name: 'ErrorCode',
    isResizable: true,
    minWidth: 160,
    maxWidth: 320
  },
  {
    key: 'Action',
    fieldName: 'Action',
    name: 'Action',
    isResizable: true,
    minWidth: 80,
    maxWidth: 80
  }
]

function VIPMerchantMonitor () {
  const [merchantsWithSupport, setMerchantsWithSupport] = useState()
  const [feedDownloadInfos, setFeedDownloadInfos] = useState()
  const [feedImportInfos, setFeedImportInfos] = useState()
  const [tokenInfos, setTokenInfos] = useState()
  const [data, setData] = useState([])
  const [displayedData, setDisplayedData] = useState([])
  const [providerId, setProviderId] = useState('')
  const [providerIdErrorMsg, setProviderIdErrorMsg] = useState()
  const [supportEmail, setSupportEmail] = useState('')
  const [supportEmailErrorMsg, setSupportEmailErrorMsg] = useState()
  const { accounts } = useMsal()
  const hasWriteAccess = accounts?.[0]?.idTokenClaims?.roles?.includes('PicAdmin.Write')
  const loadDownloaderMonitorData = async () => {
    downloaderMonitorService
      .getMerchantsWithSupport()
      .then((merchantsWithSupport) => {
        setMerchantsWithSupport(merchantsWithSupport.emailContacts)
        const providerIds = merchantsWithSupport.emailContacts.map(item => item.ProviderId)
        Promise
          .all([
            productsService.getFeedDownloadInfos(providerIds).catch((err) => console.error(err)),
            productsService.getFeedImportInfos(providerIds).catch((err) => console.error(err)),
            productsService.getTokenInfos(providerIds).catch((err) => console.error(err))
          ]).then(([feedDownloadInfos, feedImportInfos, tokenInfos]) => {
            const feedDownloadInfosLookUp = {}
            const feedImportInfosLookUp = {}
            const tokenInfosLookUp = {}
            feedDownloadInfos
              .forEach(element => {
                feedDownloadInfosLookUp[element.ProviderId] = feedDownloadInfosLookUp[element.ProviderId] ?? []
                feedDownloadInfosLookUp[element.ProviderId].push(element)
              })
            feedImportInfos
              .forEach(element => {
                feedImportInfosLookUp[element.CatalogId] = element
              })
            tokenInfos
              .forEach(element => {
                tokenInfosLookUp[element.Id] = element
              })
            setFeedDownloadInfos(feedDownloadInfosLookUp)
            setFeedImportInfos(feedImportInfosLookUp)
            setTokenInfos(tokenInfosLookUp)
          })
      })
      .catch((err) => {
        console.error('Failed to load VIPMerchantMonitor Data')
        console.error(err)
      })
  }

  const onProviderIdChange = (event, newValue) => {
    if (/^\d*$/.test(newValue)) {
      setProviderId(newValue)
      setProviderIdErrorMsg('')
    }
  }
  const onSupportEmailChange = (event, newValue) => {
    newValue = newValue.trim()
    setSupportEmail(newValue)
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(newValue) || !newValue) {
      setSupportEmailErrorMsg('')
    }
  }

  const onAddSupportForMerchant = () => {
    let isValid = true
    if (!/^\d+$/.test(providerId)) {
      setProviderIdErrorMsg('ProviderId must be a number')
      isValid = false
    }
    if (!/^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+;?)+$/.test(supportEmail)) {
      setSupportEmailErrorMsg('SupportEmail must is invalid')
      isValid = false
    }
    if (!isValid) {
      return
    }
    const newSupportEmails = supportEmail.match(/\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+/g)
    const currentSupportEmailStr = merchantsWithSupport.find(item => item.ProviderId.toString() === providerId)?.SupportEmail
    const currentSupportEmails = currentSupportEmailStr?.match(/\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+/g) ?? []
    const allEmails = [...currentSupportEmails, ...newSupportEmails]
    downloaderMonitorService
      .upsertMerchantSupportEmail(providerId, allEmails.length > 0 ? allEmails : null)
      .then(() => { loadDownloaderMonitorData() })
  }
  const onRemoveSupportForMerchant = () => {
    let isValid = true
    if (!/^\d+$/.test(providerId)) {
      setProviderIdErrorMsg('ProviderId must be a number')
      isValid = false
    }
    if (!/^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+;?)+$/.test(supportEmail)) {
      setSupportEmailErrorMsg('SupportEmail must is invalid')
      isValid = false
    }
    if (!isValid) {
      return
    }
    const supportEmailsToRemove = new Set(supportEmail.match(/\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+/g))
    const currentSupportEmailStr = merchantsWithSupport.find(item => item.ProviderId.toString() === providerId)?.SupportEmail
    const currentSupportEmails = currentSupportEmailStr?.match(/\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+/g) ?? []
    const allEmails = currentSupportEmails.filter(item => !supportEmailsToRemove.includes(item))
    downloaderMonitorService
      .upsertMerchantSupportEmail(providerId, allEmails.length > 0 ? allEmails : null)
      .then(() => { loadDownloaderMonitorData() })
  }

  useEffect(() => {
    loadDownloaderMonitorData()
  }, [])

  useEffect(() => {
    const data = []
    merchantsWithSupport?.forEach(merchant => {
      if (!feedDownloadInfos?.[merchant.ProviderId]) {
        data.push({
          TenantId: '-',
          CustomerId: '-',
          ProviderId: merchant.ProviderId,
          CatalogId: '-',
          ContactEmail: merchant.Email,
          SupportEmail: merchant.SupportEmail,
          MerchantId: '-',
          LastActivetyTime: '-',
          StatusName: '-',
          ErrorCode: <div className='error'>CatalogNotFound</div>,
          Action: <SyncButton disabled={true} />
        })
        return
      }
      feedDownloadInfos?.[merchant.ProviderId]?.forEach(catalog => {
        const feedImportInfo = feedImportInfos?.[catalog.CatalogId]
        const tokenInfo = tokenInfos?.[catalog.TokenInfoId]
        data.push({
          TenantId: util.getTenantName(catalog?.TenantId),
          CustomerId: tokenInfo?.CustomerId,
          ProviderId: catalog?.ProviderId,
          CatalogId: catalog?.CatalogId,
          ContactEmail: merchant.Email,
          SupportEmail: merchant.SupportEmail,
          MerchantId: catalog.GoogleMerchantId,
          LastActivetyTime: feedImportInfo?.DateTime,
          StatusName: util.getFeedImportStatusName(feedImportInfo?.Status),
          ErrorCode: feedImportInfo?.ErrorCode === 'None' ? '' : <div className='error'>{feedImportInfo?.ErrorCode}</div>,
          Action: <SyncButton catalogId={catalog?.CatalogId} disabled={catalog?.Enabled === false || catalog?.GoogleMerchantId.includes(',')} providerId={catalog?.ProviderId} updateFeedImportInfo={loadDownloaderMonitorData}/>
        })
      })
    })
    setData(data)
  }, [feedDownloadInfos, feedImportInfos, tokenInfos, merchantsWithSupport])

  useEffect(() => {
    const filteredData = data
      .filter(item => !providerId || item.ProviderId.toString() === providerId)
      .filter(item => !supportEmail || item.SupportEmail.includes(supportEmail))
    setDisplayedData(filteredData)
  }, [data, providerId, supportEmail])

  return (
    <div className='main-box' >
      <h1>VIPMerchant Monitor</h1>
      <hr/>
      <Stack tokens={{ childrenGap: 5 }}>
        <Stack horizontal tokens={{ childrenGap: 50 }} >
          <TextField label="ProviderId" value={providerId} onChange={onProviderIdChange} errorMessage={providerIdErrorMsg}/>
          <TextField label="SupportEmail" value={supportEmail} onChange={onSupportEmailChange} errorMessage={supportEmailErrorMsg}/>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 5 }} >
          <PrimaryButton text="Add SupportEmail For Providerid" onClick={onAddSupportForMerchant} disabled={!hasWriteAccess}/>
          <PrimaryButton text="Remove SupportEmail For Providerid" onClick={onRemoveSupportForMerchant} disabled={!hasWriteAccess}/>
        </Stack>
      </Stack>
      <DetailsList
        compact={true}
        items={displayedData}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </div>
  )
}
export default VIPMerchantMonitor

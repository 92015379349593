import axios from 'axios'
import config from '../config'
import { addErrorHandler, addTokenHandler } from './general'

const instance = axios.create({
  baseURL: config.picadminMT
})

addErrorHandler(instance)
addTokenHandler(instance)

const createFeedSyncTask = async (catalogId) => {
  const result = await instance.post('/Products/CreateFeedSyncTask',
    { },
    {
      params: {
        CatalogId: catalogId
      }
    }
  )
  return result.data
}

const getFeedDownloadInfos = async (providerIdList) => {
  const result = await instance.post('/Products/GetFeedDownloadInfos', providerIdList)
  return result.data
}

const getFeedImportInfos = async (providerIdList) => {
  const result = await instance.post('/Products/GetFeedImportInfos', providerIdList)
  return result.data
}

const getTokenInfos = async (providerIdList) => {
  const result = await instance.post('/Products/GetTokenInfos', providerIdList)
  return result.data
}

export default {
  createFeedSyncTask,
  getFeedDownloadInfos,
  getFeedImportInfos,
  getTokenInfos
}

import React from 'react'
import { ProductsStatusCard } from './productsStatusCard'
import { DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList'
import PropTypes from 'prop-types'

export const ProductsStatusList = (props) => {
  let syncUpData
  if (!props.productStatus?.LastSyncUpStatus) {
    syncUpData = [
      {
        LastSyncUpTime: '\\',
        LastSyncUpStatus: '\\'
      }
    ]
  } else {
    syncUpData = [props.productStatus]
  }
  const approvedCount = props.productStatus ? props.productStatus.Approved : '\\'
  const pendingCount = props.productStatus ? props.productStatus.Pending : '\\'
  const rejectedCount = props.productStatus ? props.productStatus.Rejected : '\\'
  return (
    <>
      <p className='shopify-title'>Products Status</p>
      <div className='error'>{props.error}</div>
      <div style={listStyle}>
        <ProductsStatusCard backgroundColor="#107C10" count={approvedCount} status="Listed to Microsoft Advertising"/>
        <ProductsStatusCard backgroundColor="#0078D4" count={pendingCount} status="Pending"/>
        <ProductsStatusCard backgroundColor="#EA4719" count={rejectedCount} status="Issues to resolve"/>
        <DetailsList
          items={syncUpData}
          compact={isCompactMode}
          columns={columns}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </div>
    </>
  )
}

const listStyle = {
  display: 'flex',
  marginTop: '16px',
  marginLeft: '12px'
}
const columns = [
  {
    key: 'LastSyncUpTime',
    fieldName: 'LastSyncUpTime',
    name: 'Latest SyncUp Time(UTC)',
    minWidth: 256,
    maxWidth: 312
  },
  {
    key: 'LastSyncUpStatus',
    fieldName: 'LastSyncUpStatus',
    name: 'Latest SyncUp Status',
    minWidth: 312,
    maxWidth: 400
  }
]

const isCompactMode = true

ProductsStatusList.propTypes = {
  productStatus: PropTypes.object,
  error: PropTypes.string
}

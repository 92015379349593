import '../../../asset/css/pilotManagement.css'

import { ActionButton, DetailsList, DetailsListLayoutMode, PrimaryButton, SelectionMode, Shimmer, Stack, Text, TextField } from '@fluentui/react'
import React, { useEffect, useState } from 'react'

import FeatureCreateModal from './featureCreateModal'
import PropTypes from 'prop-types'
import { getPilotFeatures } from '../../../service/pilot.service'
import tenant from '../model/tenant'
import { useQuery } from '@tanstack/react-query'

function PilotFeatureList (props) {
  const columns = [
    { key: 'id', name: 'Id', fieldName: 'id', minWidth: 100, maxWidth: 100 },
    { key: 'tenantId', name: 'TenantId', fieldName: 'tenantId', minWidth: 100, maxWidth: 160 },
    { key: 'featureName', name: 'FeatureName', fieldName: 'featureName', minWidth: 240, maxWidth: 240, isResizable: true },
    { key: 'description', name: 'Description', fieldName: 'description', minWidth: 320, isResizable: true },
    { key: 'isGA', name: 'IsGA', fieldName: 'isGA', minWidth: 200, maxWidth: 200 },
    { key: 'isEnabled', name: 'IsEnabled', fieldName: 'isEnabled', minWidth: 200, maxWidth: 200 },
    { key: 'action', name: 'Action', fieldName: 'action', minWidth: 200, maxWidth: 200 }
  ]

  const [id, setId] = useState('')
  const [tenantId, setTenantId] = useState('')
  const [featureName, setFeatureName] = useState('')
  const [displayedData, setDisplayedData] = useState([])
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const { data: pilotFeatureListData, isLoading: pilotFeatureListLoading, isError: pilotFeatureListError } = useQuery({
    queryKey: 'getPilotFeatures',
    queryFn: () => getPilotFeatures()
  })

  useEffect(() => {
    if (pilotFeatureListData) {
      const filteredData = pilotFeatureListData?.pilotFeatures
        .filter(pilotFeature => {
          return (
            (!id || pilotFeature.id.toString() === id) &&
            (!tenantId || pilotFeature.tenantId.toString() === tenantId) &&
            (!featureName || pilotFeature.name.toString().toLowerCase().includes(featureName.toLowerCase()))
          )
        })
        .map(({ id, tenantId, name, description, isGA, isEnabled }) => ({
          id: <div className='feature-list-cell'>{id}</div>,
          tenantId: <div className='feature-list-cell'>{`${tenantId} _ [${tenant[tenantId]}]`}</div>,
          featureName: <div className='feature-list-cell'>{name}</div>,
          description: <div className='feature-list-cell'>{description}</div>,
          isGA: (
            <div className='feature-list-cell'>
              <div className={isGA ? 'is-feature-ga' : 'is-feature-not-ga'}>
                {isGA ? 'GA' : 'Not GA'}
              </div>
            </div>
          ),
          isEnabled: (
            <div className='feature-list-cell'>
              <div className={isEnabled ? 'is-feature-enabled' : 'is-feature-disabled'}>
                {isEnabled ? 'Enabled' : 'Disabled'}
              </div>
            </div>
          ),
          action: (
            <div className='feature-list-cell'>
              <PrimaryButton text="Manage" onClick={() => handleManageClick(id)} />
            </div>
          )
        }))

      setDisplayedData(filteredData)
      setCurrentPage(1)
    }
  }, [pilotFeatureListData, id, tenantId, featureName])

  const handleIdChange = (event, newValue) => {
    if (/^\d*$/.test(newValue)) {
      setId(newValue)
    }
  }

  const handleTenantIdChange = (event, newValue) => {
    if (/^\d*$/.test(newValue)) {
      setTenantId(newValue)
    }
  }

  const handleFeatureNameChange = (event, newValue) => {
    setFeatureName(newValue)
  }

  const handleModalOpen = () => {
    setIsCreateModalOpen(true)
  }

  const handleModalDismiss = () => {
    setIsCreateModalOpen(false)
  }

  const handleManageClick = (id) => {
    props.setSelectedId(id)
  }

  const renderPagination = () => {
    const totalPage = displayedData?.length ? Math.ceil(displayedData.length / itemsPerPage) : 1

    return (
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign='center' horizontalAlign='center' >
        <ActionButton
          iconProps={{ iconName: 'ChevronLeft' }}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
        <Text variant='large'>{ currentPage } / { totalPage }</Text>
        <ActionButton
          iconProps={{ iconName: 'ChevronRight' }}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPage}
        />
      </Stack>
    )
  }

  const renderDetailsList = () => {
    if (pilotFeatureListLoading || pilotFeatureListError) {
      const shimmeredItems = Array.from({ length: 5 }).map((_, index) => ({
        id: <Shimmer />,
        tenantId: <Shimmer />,
        featureName: <Shimmer />,
        description: <Shimmer />,
        isGA: <Shimmer />,
        isEnabled: <Shimmer />,
        action: <Shimmer />
      }))
      return (
        <DetailsList
          items={shimmeredItems}
          columns={columns}
          setKey='none'
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          isHeaderVisible={true}
          compact={true}
        />
      )
    }

    return (
      <DetailsList
        items={displayedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
        columns={columns}
        setKey='none'
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        isHeaderVisible={true}
        compact={true}
      />
    )
  }

  return (
    <div>
      <Stack tokens={{ childrenGap: 32 }}>
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack horizontal tokens={{ childrenGap: 48 }} verticalAlign='center' >
            <h2>Pilot Features</h2>
            <PrimaryButton text="Create New Pilot Feature" onClick={handleModalOpen} />
          </Stack>
          <Stack tokens={{ childrenGap: 4 }}>
            <Text variant='large'>Filter</Text>
            <Stack horizontal tokens={{ childrenGap: 48 }} >
              <TextField label="Id" value={id} onChange={handleIdChange} />
              <TextField label="TenantId" value={tenantId} onChange={handleTenantIdChange} />
              <TextField label="FeatureName" value={featureName} onChange={handleFeatureNameChange} />
            </Stack>
          </Stack>
          {renderDetailsList()}
          {renderPagination()}
        </Stack>
      </Stack>
      <FeatureCreateModal isOpen={isCreateModalOpen} onClose={handleModalDismiss} />
    </div>
  )
}

PilotFeatureList.propTypes = {
  setSelectedId: PropTypes.func
}

export default PilotFeatureList

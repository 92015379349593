import * as React from 'react'
import { DetailsList, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList'
import PropTypes from 'prop-types'
import { PrimaryButton, Dialog, DialogType, DialogFooter } from '@fluentui/react'
import { createFeedSyncTask } from '../../service/shopify.service'
import { useBoolean } from '@fluentui/react-hooks'

const columns = [
  {
    key: 'CatalogName',
    fieldName: 'CatalogName',
    name: 'Catalog Name',
    minWidth: 220,
    maxWidth: 330,
    isResizable: true
  },
  {
    key: 'TargetCountry',
    fieldName: 'TargetCountry',
    name: 'Market',
    minWidth: 48,
    maxWidth: 72,
    isResizable: true
  },
  {
    key: 'Enabled',
    fieldName: 'Enabled',
    name: 'Enabled',
    isResizable: true,
    minWidth: 64,
    maxWidth: 96
  },
  {
    key: 'ModifiedOn',
    fieldName: 'ModifiedOn',
    name: 'Modified On',
    isResizable: true,
    minWidth: 160,
    maxWidth: 160
  },
  {
    key: 'LastActivetyTime',
    fieldName: 'LastActivetyTime',
    name: 'Last Activety Time',
    isResizable: true,
    minWidth: 160,
    maxWidth: 160
  },
  {
    key: 'StatusName',
    fieldName: 'StatusName',
    name: 'Status',
    isResizable: true,
    minWidth: 160,
    maxWidth: 160
  },
  {
    key: 'Action',
    fieldName: 'Action',
    name: 'Action',
    isResizable: true,
    minWidth: 120,
    maxWidth: 120
  }
]

const isCompactMode = true

const SyncButton = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const [errorMsg, setErrorMsg] = React.useState('Unknow error')
  const onSync = async () => {
    try {
      setLoading(true)
      await createFeedSyncTask(props.catalogId)
      await props.updateFeedImportInfo()
    } catch (error) {
      setErrorMsg(error?.response?.data?.ExceptionMessage ?? 'Unknow error')
      toggleHideDialog()
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Fail to sync',
          subText: errorMsg
        }}
        modalProps={{
          styles: { main: { minHeight: 64 } }
        }}
      >
        <DialogFooter >
          <PrimaryButton onClick={toggleHideDialog} text="Close" />
        </DialogFooter>
      </Dialog>
      <PrimaryButton onClick={onSync} disabled={loading || !props.catalogId || props.disabled} >
      Sync Now
      </PrimaryButton>
    </>
  )
}
SyncButton.propTypes = {
  catalogId: PropTypes.number,
  disabled: PropTypes.bool,
  updateFeedImportInfo: PropTypes.func
}

export const MmcCatalogsList = (props) => {
  let mmcCatalogs = [{
    CatalogName: '\\',
    TargetCountry: '\\',
    Enabled: '\\',
    ModifiedOn: '\\',
    LastActivetyTime: '\\',
    StatusName: '\\',
    Action: <PrimaryButton disabled={true} >Sync</PrimaryButton>
  }]
  if (props.mmcInfo?.MmcCatalogs) {
    const data = {}
    props.mmcInfo.MmcCatalogs
      .filter(items => items.CatalogName.startsWith('ShopifyImport_'))
      .forEach(item => {
        data[item.ItemCollectionId] = {
          CatalogName: item.CatalogName,
          TargetCountry: item.TargetCountry,
          Enabled: '\\',
          ModifiedOn: '\\',
          LastActivetyTime: '\\',
          StatusName: '\\',
          Action: <SyncButton disabled={true} />
        }
      })
    if (props.feedDownloadInfo) {
      props.feedDownloadInfo
        .forEach(item => {
          data[item.CatalogId].Enabled = item.Enabled
          data[item.CatalogId].ModifiedOn = item.ModifiedOn
          if (item.Enabled) {
            data[item.CatalogId].Action = <SyncButton disabled={false} catalogId={item.CatalogId} updateFeedImportInfo={props.updateFeedImportInfo}/>
          }
        })
    }
    if (props.feedImportInfo) {
      const statusMap = {
        0: 'None',
        1: 'Created',
        2: 'ImportInProgress',
        3: 'Imported',
        4: 'ImportFailed'
      }
      props.feedImportInfo
        .forEach(item => {
          data[item.CatalogId].LastActivetyTime = item.DateTime
          data[item.CatalogId].StatusName = statusMap[item.Status]
        })
    }
    mmcCatalogs = Object.values(data)
  }
  return (
    <DetailsList
      items={mmcCatalogs}
      compact={isCompactMode}
      columns={columns}
      selectionMode={SelectionMode.none}
      setKey="none"
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
    />
  )
}

MmcCatalogsList.propTypes = {
  mmcInfo: PropTypes.object,
  feedDownloadInfo: PropTypes.array,
  feedImportInfo: PropTypes.array,
  error: PropTypes.string,
  updateFeedImportInfo: PropTypes.func
}

import { useMsal } from '@azure/msal-react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import picadminQueryService from '../../../service/query.service'
import { updatePersonalQueryList } from '../../../store/feature/querySlice'
import QueryList from './queryList'
function PersonalQueryList (props) {
  const dispatch = useDispatch()
  const { accounts } = useMsal()
  const userName = accounts[0] && accounts[0].username
  const personalQueryList = useSelector(state => state.query.personalQueryList)

  async function loadpersonalQueries () {
    const data = await picadminQueryService.getPersonalQueries(userName)
    dispatch(updatePersonalQueryList(data))
  }

  useEffect(() => {
    loadpersonalQueries()
  }, [])

  return (
    <QueryList
      title='Personal Queries'
      data={personalQueryList}
    />
  )
}
export default PersonalQueryList

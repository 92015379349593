/* eslint-disable camelcase */
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
function CatalogId_CatalogName (props) {
  const columns = [
    {
      key: 'CatalogId',
      fieldName: 'CatalogId',
      name: 'CatalogId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'CatalogName',
      fieldName: 'CatalogName',
      name: 'CatalogName',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    }
  ]
  return (
    <DetailsList
      items={props.data}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
    />
  )
}
CatalogId_CatalogName.propTypes = {
  data: PropTypes.array.isRequired
}
export default CatalogId_CatalogName

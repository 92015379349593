import { ActionButton, Stack } from '@fluentui/react'
import React, { useState } from 'react'

import DomainManageSection from './components/domainManageSection'
import FeatureEditSection from './components/featureEditSection'
import PilotFeatureList from './components/pilotFeatureList'

function PilotManagement () {
  const [selectedId, setSelectedId] = useState(null)
  const [isEditFeatureDetail, setIsEditFeatureDetail] = useState(false)

  const renderNotes = () => {
    return (
      <div>
        <h3 >What is pilot feature</h3>
        <ul>
          <li>It is a feature that is in the pilot phase and is not yet available to all users</li>
        </ul>
        <h3 className='note-red'>Attention:</h3>
        <ul>
          <li>Only the feature owner can create and edit the pilot feature</li>
          <li>For the released content, please make sure that the information of SI and Prod is consistent.</li>
          <li>Set the piot feature carefully to avoid affecting the user experience</li>
        </ul>
        <h3>Usage steps :</h3>
        <ol>
          <li className='note-bold'>Click &quot;Create New Pilot Feature&quot; to create a new pilot feature</li>
          <ul>
            <li>Fill in the feature name, description, and tenant, set the GA and enabled status</li>
            <li>The pilot feature name should be unique</li>
          </ul>
          <li className='note-bold'>Click &quot;Manage&quot; to view and edit the pilot feature</li>
          <ul>
            <li>The pilot feature is enabled by default. The feature owner can disable it if needed</li>
            <li>Set the feature to <strong className='note-green'>GA</strong> when it is ready to be released to all users</li>
            <li>Set the feature to <strong className='note-red'>Disabled</strong> when it is no longer accessible to any user</li>
          </ul>
          <li className='note-bold'>Use the domain management section to manage the domains that can access the pilot feature</li>
        </ol>
      </div>
    )
  }

  if (selectedId) {
    return (
      <div className='main-box'>
        <div className='flex-row'>
          <h1>Pilot Management</h1>
        </div>
        <hr />
        <Stack tokens={{ childrenGap: 8 }}>
          <ActionButton
            text="Back to Feature List"
            iconProps={{ iconName: 'Back' }}
            onClick={() => setSelectedId(null)}
          />
          <FeatureEditSection
            selectedId={selectedId}
            isEditFeatureDetail={isEditFeatureDetail}
            setIsEditFeatureDetail={setIsEditFeatureDetail}
          />
        </Stack>
        <br />
        <hr />
        <div className={isEditFeatureDetail ? 'div-disabled' : ''}>
          <DomainManageSection selectedId={selectedId} />
        </div>
      </div>
    )
  }

  return (
    <div className='main-box'>
      <div className='flex-row'>
        <h1>Pilot Management</h1>
      </div>
      <hr />
      {renderNotes()}
      <hr />
      <PilotFeatureList setSelectedId={setSelectedId} />
    </div>
  )
}

export default PilotManagement

/* eslint-disable camelcase */
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'
function CatalogId_Market_GroupId_CatalogMerchantIds (props) {
  const columns = [
    {
      key: 'CatalogId',
      fieldName: 'CatalogId',
      name: 'CatalogId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'GroupId',
      fieldName: 'GroupId',
      name: 'GroupId',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'Market',
      fieldName: 'Market',
      name: 'Market',
      isResizable: true,
      minWidth: 120,
      maxWidth: 120
    },
    {
      key: 'CatalogMerchantIds',
      fieldName: 'CatalogMerchantIds',
      name: 'CatalogMerchantIds',
      isResizable: true,
      minWidth: 120
    }
  ]
  const data = props.data.map(item => {
    return {
      CatalogId: item.CatalogId,
      CatalogMerchantIds: item.CatalogMerchantIds.join(','),
      GroupId: item.GroupId,
      Market: item.Market
    }
  })
  return (
    <DetailsList
      items={data}
      columns={columns}
      selectionMode={SelectionMode.none}
      layoutMode={DetailsListLayoutMode.justified}
    />
  )
}
CatalogId_Market_GroupId_CatalogMerchantIds.propTypes = {
  data: PropTypes.array.isRequired
}
export default CatalogId_Market_GroupId_CatalogMerchantIds

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import AccessController from './component/accessController'
import DownloaderMonitor from './view/downloaderMonitor'
import Footer from './view/footer'
import GoogleInfo from './view/google'
import Header from './view/header'
import PilotManagement from './view/PilotManagement'
import Queries from './view/queries'
import React from 'react'
import ShopifyInfo from './view/Shopify'
import ToolBigCommerceUnbindAdsAccount from './view/tools/bigcommerceUnbindAdsAccount'
import ToolGMCMultiStoreImport from './view/tools/gmcMultiStoreImport'
import ToolGMCStoreWhiteList from './view/tools/gmcWhiteList'
import ToolPrestaShopUnbindAdsAccount from './view/tools/prestashopUnbindAdsAccount copy'
import ToolShopifyUnbindAdsAccount from './view/tools/shopifyUnbindAdsAccount'
import VIPMerchantMonitor from './view/VIPMerhcantMonitor'
import accessRight from './model/accessRight'
import path from './model/path'
import { setMsalContext } from './service/general'
import { useMsal } from '@azure/msal-react'

function Router () {
  const msalContext = useMsal()
  setMsalContext(msalContext)
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path={path.VIPMerchantMonitor} element={<AccessController requiredAccessRight={accessRight.Read} element={<VIPMerchantMonitor/>}/>} />
        <Route path={path.downloaderMonitor} element={<AccessController requiredAccessRight={accessRight.Read} element={<DownloaderMonitor/>}/>} />
        <Route path={path.query} element={<AccessController requiredAccessRight={accessRight.Read} element={<Queries/>}/>} />
        <Route path={path.shopify} element={<AccessController requiredAccessRight={accessRight.Read} element={<ShopifyInfo/>}/>} />
        <Route path={path.google} element={<AccessController requiredAccessRight={accessRight.Read} element={<GoogleInfo/>}/>} />
        <Route path={path.gmcToolWhiteList} element={<AccessController requiredAccessRight={accessRight.Write} element={<ToolGMCStoreWhiteList/>}/>} />
        <Route path={path.gmcToolMultiStoreImport} element={<AccessController requiredAccessRight={accessRight.Write} element={<ToolGMCMultiStoreImport/>}/>} />
        <Route path={path.shopifyToolUnbindAdsAccount} element={<AccessController requiredAccessRight={accessRight.Write} element={<ToolShopifyUnbindAdsAccount/>}/>} />
        <Route path={path.bigcommerceToolUnbindAdsAccount} element={<AccessController requiredAccessRight={accessRight.Write} element={<ToolBigCommerceUnbindAdsAccount/>}/>} />
        <Route path={path.prestashopToolUnbindAdsAccount} element={<AccessController requiredAccessRight={accessRight.Write} element={<ToolPrestaShopUnbindAdsAccount/>}/>} />
        <Route path={path.pilotManagement} element={<AccessController requiredAccessRight={accessRight.Write} element={<PilotManagement/>} />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  )
}

export default Router

import React, { useEffect, useState } from 'react'
import downloaderMonitorService from '../../service/downloaderMonitor.service'
import { Link } from '@fluentui/react'
import util from '../../util'
import LineChart from './lineChart'
function DownloaderMonitor () {
  const [data, setData] = useState([])
  const [cosmosUrl, setCosmosUrl] = useState('')

  const loadDownloaderMonitorData = async () => {
    const result = await downloaderMonitorService.getDownloadTaskMonitorData()
    const kpiData = result.kpi_data.map(item => {
      const _item = { ...item }
      _item.created_date = new Date(item.created_date)
      return _item
    })
    kpiData.sort((a, b) => a.created_date - b.created_date)
    setData(kpiData)
    setCosmosUrl(result.cosmos_url)
  }

  useEffect(() => {
    loadDownloaderMonitorData()
  }, [])
  console.log(data)
  const today = new Date()
  const thirtyDaysBeforeToday = new Date()
  thirtyDaysBeforeToday.setDate(thirtyDaysBeforeToday.getDate() - 30)

  const internalError = data.map(item => item.internal_error_count_20)
  const authorizationError = data.map(item => item.authorization_error_21)
  const noMatchingOffers = data.map(item => item.no_matching_offers_22)
  const shopifyApiNotFound = data.map(item => item.shopify_api_not_found_25)
  const shopifycurrencyNotMatch = data.map(item => item.shopify_currency_not_match_26)
  const terminatedCount = data.map(item => item.terminated_count)
  const pendingLongTimeCount = data.map(item => item.pending_long_time_count)

  const startDate = data.length > 0 ? data[0].created_date : thirtyDaysBeforeToday
  const endDate = data.length > 0 ? data[data.length - 1].created_date : today
  return (
    <div className='main-box' >
      <h1>Downloader Monitor</h1>
      <hr/>
      <LineChart
        data={[internalError, authorizationError, noMatchingOffers, shopifyApiNotFound, shopifycurrencyNotMatch, terminatedCount, pendingLongTimeCount]}
        axis={['internalError', 'authorizationError', 'noMatchingOffers', 'shopifyApiNotFound', 'shopifycurrencyNotMatch', 'terminatedCount', 'pendingLongTimeCount']}
        granularity='daily'
        startDate={startDate}
        endDate={endDate}
      />
      <Link href={cosmosUrl} disabled={!util.isValidString(cosmosUrl)} target='_blank'>Reference: {cosmosUrl}</Link>
    </div>
  )
}
export default DownloaderMonitor

import * as React from 'react'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { useBoolean } from '@fluentui/react-hooks'

const modalPropsStyles = { main: { maxWidth: 450 } }
const dialogContentProps = {
  type: DialogType.normal,
  title: 'No Access',
  subText: 'No access to this resource. Please contact PIC team.'
}

const NoAccessPage = () => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false)
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles
    })
  )

  return (
    <>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text="Ok" />
        </DialogFooter>
      </Dialog>
    </>
  )
}
export default NoAccessPage

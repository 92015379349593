/* eslint-disable camelcase */
import { TextField } from '@fluentui/react'
import * as React from 'react'
import PropTypes from 'prop-types'

function UpdateOldGroupSQL (props) {
  return (
    <TextField label='UpdateOldGroupSQL' value={props.data.join('\n')} readOnly multiline autoAdjustHeight/>
  )
}
UpdateOldGroupSQL.propTypes = {
  data: PropTypes.array.isRequired
}
export default UpdateOldGroupSQL

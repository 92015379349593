import axios from 'axios'
import config from '../config'
import Pako from 'pako'
import { addErrorHandler, addTokenHandler } from './general'

const instance = axios.create({
  baseURL: config.picadminMT,
  transformRequest: axios.defaults.transformRequest.concat(
    function (data, headers) {
      // compress strings if over 128 KB
      if (typeof data === 'string' && data.length > 1024 * 128) {
        headers['Content-Encoding'] = 'gzip'
        return Pako.gzip(data)
      } else {
        // delete is slow apparently, faster to set to undefined
        headers['Content-Encoding'] = undefined
        return data
      }
    }
  )
})

addErrorHandler(instance)
addTokenHandler(instance)

const retrieveMultiStoreImportData = async (providerId, customerId, accountId, market) => {
  const result = await instance.get('/MultiStoreImport/RetrieveMultiStoreImportData', {
    params: {
      providerId,
      customerId,
      accountId,
      market
    }
  }
  )
  return result.data
}

const upsertMultiStoreImport = async (requestBody) => {
  const result = await instance.post('/MultiStoreImport/UpsertMultiStoreImport',
    requestBody,
    {
    }
  )
  return result.data
}

export default {
  retrieveMultiStoreImportData,
  upsertMultiStoreImport
}
